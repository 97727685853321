import styled from 'styled-components/macro';

const NotFound = () => {
  return (
    <S.Continer>
      <h1>oops! page not found</h1>
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 88px);

  h1 {
    display: flex;
    align-items: center;
    font-weight: 200;
  }
`;

export default NotFound;
