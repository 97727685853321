import { Routes, Route } from 'react-router-dom';

import ArtistStatement from 'views/ArtistStatement';
import CookiePolicy from 'views/CookiePolicy';
import Faqs from 'views/Faqs';
import Landing from 'views/Landing';
import NftPreview from 'views/Nft/NftPreview';
import NftReport from 'views/Nft/NftReport';
import NotFound from 'views/NotFound';
import TermsConditions from 'views/TermsConditions';
import UnderConstruction from 'views/UnderConstruction';

const RouterComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/nft/preview" element={<NftPreview />} />
      <Route path="/nft/preview/:id" element={<NftPreview />} />
      <Route
        path="/nft/report/:address/:tokenId/:chainId"
        element={<NftReport />}
      />
      <Route path="/artist-statement" element={<ArtistStatement />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/under-construction" element={<UnderConstruction />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterComponent;
