import { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import InnerImageZoom from 'react-inner-image-zoom';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import closeIcon from 'assets/images/icons/close-button.svg';
import fullScreenIcon from 'assets/images/icons/full-screen-button.svg';
import noImage from 'assets/images/no-image.jpg';

import * as S from './styles';

interface IPreviewImage {
  src: string;
  isSliderMode?: boolean; //TODO: workaround - refactor
  [rest: string]: any;
}

const PreviewImage = ({ src, isPageMode }: IPreviewImage) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onError = (e) => {
    e.target.src = noImage;
  };

  return (
    <>
      <S.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-nft-preview"
        aria-describedby="modal-modal-preview"
      >
        <>
          <S.CloseButton src={closeIcon} onClick={handleClose} />
          <S.Image>
            <InnerImageZoom
              src={src || ''}
              zoomScale={2}
              hideHint
              hideCloseButton
            />
          </S.Image>
        </>
      </S.Modal>
      <S.ImgContainer isPageMode={isPageMode}>
        <CircularProgress
          style={{ position: 'absolute', color: 'grey', zIndex: 0 }}
        />
        <S.OpenButton src={fullScreenIcon} onClick={handleOpen} />
        <img src={src} onError={onError} alt="" />
      </S.ImgContainer>
    </>
  );
};

export default PreviewImage;
