import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as QuestionMarkIcon } from 'assets/images/icons/circle-question-mark.svg';
import { mediaQueries } from 'styles/media';

interface Modal {
  children: JSX.Element;
  isOpen: any;
  close: () => void;
  bgColor?: string;
  backdropBgColor?: string;
  info?: any;
}

const Modal = ({
  isOpen,
  close,
  children,
  bgColor,
  backdropBgColor,
  info,
}: Modal) => {
  const matchesMobile = useMediaQuery('(max-width:960px)');

  return (
    <S.Modal
      open={!!isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ invisible: true }}
      hideBackdrop={matchesMobile}
      backdropBgColor={backdropBgColor}
    >
      <S.StyledBox bgColor={bgColor}>
        <S.CloseButton role="button" onClick={close}>
          Close &nbsp; <CloseIcon />
        </S.CloseButton>
        {info && (
          <S.InfoButton role="button" onClick={() => 1}>
            <QuestionMarkIcon /> &nbsp; Learn More
          </S.InfoButton>
        )}
        {children}
      </S.StyledBox>
    </S.Modal>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

interface StyledBox {
  bgColor: string;
}

S.StyledBox = styled(Box)<StyledBox>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  max-width: 788px;
  height: auto;
  padding: 64px 64px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px rgba(101, 102, 243, 0.2);
  background-color: white;

  ${mediaQueries.md} {
    padding: 72px 24px 24px 24px;
    border-radius: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    box-shadow: none;
  }

  ${({ bgColor }) => {
    if (bgColor === 'black') {
      return css`
        background-color: black;
        color: white;
        svg {
          fill: white !important;
        }
      `;
    }
  }}
`;

interface Modal {
  backdropBgColor?: string;
}

S.Modal = styled(MuiModal)<Modal>`
  top: 88px !important;

  ${mediaQueries.md} {
    z-index: 210 !important;
  }

  div {
    outline: none !important;
  }
  .MuiBackdrop-root {
    background-color: ${(props) => props.backdropBgColor || 'white'};
  }
`;

S.CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--darkGrey);
  cursor: pointer;
  svg {
    fill: black;
  }
`;

S.InfoButton = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--darkGrey);
  cursor: pointer;
  svg {
    fill: black;
  }
`;

export default Modal;
