import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Main = styled.main`
  margin-top: 42px;
  margin-bottom: 42px;
  display: grid;
  grid-gap: 42px;
  justify-items: center;
  height: fit-content;

  ${mediaQueries.xs} {
    grid-gap: 20px;
  }
`;

export const SwitchContainer = styled.div`
  width: 200px;
  height: 40px;
`;