import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import { mediaQueries } from 'styles/media';

const NavBarMenu = ({ tooggleSearchNft }) => {
  return (
    <nav>
      <S.NavItems>
        <li>
          <S.Link to="/#gallery-landing">Examples</S.Link>
        </li>
        <li>
          <S.Link to="/faqs">FAQs</S.Link>
        </li>
        <li>
          <PillButton
            label="Grade an NFT"
            variant="white"
            icon="isotype"
            onClick={tooggleSearchNft}
          />
        </li>
      </S.NavItems>
    </nav>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.NavItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  justify-content: center;
  grid-gap: 48px;
  grid-auto-flow: column;
  align-items: center;

  ${mediaQueries.md} {
    display: none;
  }
`;

S.Link = styled(Link)`
  transition: 0.4s;
  text-decoration: none;
  color: black;
`;

export default NavBarMenu;
