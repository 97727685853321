import { useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import TextField from 'components/Form/TextField';
import SubscribeError from 'components/Subscribe/SubscribeError';
import SubscribeSuccess from 'components/Subscribe/SubscribeSuccess';
import { subscribeEmail } from 'services';
import { mediaQueries } from 'styles/media';

interface Values {
  email: string | '';
}

const Subscribe = ({ close }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('iddle');
  const [subscriptionError, setSubscriptionError] = useState('');
  const [email, setEmail] = useState('');
  const matchesMobile = useMediaQuery('(max-width:960px)');

  function validateEmail(val) {
    let error;
    const regEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!val) {
      error = 'Required';
    } else if (!regEx.test(val)) {
      error = 'Enter a valid email';
    }
    return error;
  }

  const onSubmit = (values: Values) => {
    const { email } = values;

    const callback = (response) => {
      if (response.status === 'error') {
        const { errorType, message } = response.errors[0];
        setSubscriptionStatus('error');
        if (errorType === 'INVALID_EMAIL') {
          setSubscriptionError('This email is not accepted');
        } else {
          setSubscriptionError(message || 'Error!');
        }
      } else {
        setEmail(email);
        setSubscriptionStatus('success');
      }
    };

    const callbackError = (error) => {
      setSubscriptionError('Error!');
      setSubscriptionStatus('error!');
    };

    subscribeEmail(email, callback, callbackError);
  };

  const resetStatus = () => {
    setSubscriptionStatus('iddle');
  };

  switch (subscriptionStatus) {
    case 'iddle':
      return (
        <S.Continer>
          <h2>Stay in Touch...</h2>
          <p>Subscribe and stay up to date on the newest updates.</p>

          <Formik initialValues={{ email: '' }} onSubmit={onSubmit}>
            {({ errors, touched, values, isValid }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <S.InputWrapper>
                  <Field
                    as={TextField}
                    type="name"
                    name="email"
                    value={values.email}
                    placeholder="Enter your email address"
                    validate={validateEmail}
                    errorMessage={errors.email}
                    touched={touched.email}
                    width={matchesMobile ? '100%' : '400px'}
                  />
                  <PillButton
                    type="submit"
                    label="Subscribe Me"
                    variant="primary"
                    width="100%"
                    disabled={
                      Object.values(values).some(
                        (formElementVal) => formElementVal === ''
                      ) || !isValid
                    }
                    icon="arrowFordward"
                    style={
                      !matchesMobile
                        ? {
                            borderRadius: '0 28px 28px 0',
                            position: 'relative',
                            left: '-8px',
                            height: '59px',
                          }
                        : {
                            position: 'absolute',
                            bottom: 0,
                          }
                    }
                  />
                </S.InputWrapper>
              </Form>
            )}
          </Formik>
        </S.Continer>
      );
    case 'success':
      return <SubscribeSuccess close={close} email={email} />;
    case 'error':
      return (
        <SubscribeError
          close={close}
          errorMessage={subscriptionError}
          resetStatus={resetStatus}
        />
      );
    default:
      return null;
  }
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  height: 100%;
  position: relative;
`;

S.InputWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: baseline;

  ${mediaQueries.md} {
    flex-direction: column;
  }
`;

export default Subscribe;
