import { useState, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ContentPreloader from 'components/ContentPreloader';
import Grade from 'components/Grade';
import {
  useGetFullReportQuery,
  useGetSmartContractCodeQuery,
  useGetPdfQuery,
  useGetEnergyConsumptionQuery,
} from 'services';
import { useAppSelector } from 'store/hooks';
import { formatDate } from 'utils';

import ActionButtons from './components/ActionButtons';
import DownloadPdfModal from './components/DownloadPdfModal';
import ErrorReportModal from './components/ErrorReportModal';
import ReportPreloader from './components/ReportPreloader';
import ReportFull from './ReportFull';
import ReportSummary from './ReportSummary';
import * as S from './styles';

const NftReport = () => {
  interface IError {
    error: string;
    message: string;
    statusCode: number | null;
  }

  const [stepAnimationVisible, setStepAnimationVisible] = useState(true);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfSkipRequest, setPdfSkipRequest] = useState(true);
  const [hederaBtnClicked, setHederaBtnClicked] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [reqError, setReqError] = useState<IError>({
    error: '',
    message: '',
    statusCode: null,
  });
  const matchesMobile = useMediaQuery('(max-width:960px)');
  const navigate = useNavigate();
  const nftDetail = useAppSelector((state) => state.nft.lastPreviewed);
  const address = useParams().address || '';
  const tokenId = useParams().tokenId || '';
  const chainId = useParams().chainId || '';
  const [search] = useSearchParams();
  const reportType = search.get('type') ?? 'lite';

  const {
    data: dataReport,
    isLoading: isLoading,
    error: errorReport,
    isSuccess: isSuccessReport,
  } = useGetFullReportQuery({
    address: address,
    tokenId: tokenId,
    chainId: chainId,
  });

  const nftName = nftDetail?.name || dataReport?.nft?.name || '';
  const nftTokenId = nftDetail?.token_id || dataReport?.nft?.token_id || '';
  const tokenType = dataReport?.nft?.token_type;
  let skipEnergyReport = true;

  if (isSuccessReport) {
    if (tokenType !== 'ERC-1155') {
      skipEnergyReport = false;
    }
  }

  const dataEnergy = useGetEnergyConsumptionQuery(
    {
      address: address,
      tokenId: tokenId,
      chainId: chainId,
    },
    {
      skip: skipEnergyReport,
    }
  );

  const {
    data: dataPdf,
    error: errorPdf,
    isLoading: isLoadingPdf,
    isSuccess: isSuccessPdf,
  } = useGetPdfQuery(
    {
      address: address,
      tokenId: tokenId,
      chainId: chainId,
    },
    {
      skip: pdfSkipRequest,
    }
  );

  const { data: contractCode, error: errorContractCode } =
    useGetSmartContractCodeQuery(
      {
        address: address,
        chainId: chainId,
      },
      {
        skip: chainId === '2',
      }
    );

  // Preloader animation
  useEffect(() => {
    const interval = setTimeout(() => {
      setStepAnimationVisible(false);
    }, 6500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Handle request error msg
  useEffect(() => {
    if (errorReport) {
      setErrorModalVisible(true);

      if ('data' in errorReport) {
        const errorData = errorReport.data as IError;
        setReqError(errorData);
      }
    }
  }, [errorReport]);

  const seeFullReport = () => {
    navigate('?type=full');
  };

  const seeLiteReport = () => {
    navigate('?type=lite');
  };

  const showPdfModal = () => {
    setPdfSkipRequest(false);
    setPdfModalVisible((prevState) => !prevState);
  };

  const handleHedera = () => {
    setPdfSkipRequest(false);
    setHederaBtnClicked(true);
  };

  const toggleErrorModal = () => {
    setErrorModalVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const requestOk = !errorPdf && isSuccessPdf;

    if (pdfModalVisible) {
      if (requestOk) {
        const link = document.createElement('a');
        link.href = dataPdf.condition_report.pdf;
        link.setAttribute('download', 'nft-source-code.pdf');
        link.setAttribute('target', '_blank');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    if (!pdfSkipRequest && hederaBtnClicked) {
      if (requestOk) {
        window.open(dataPdf?.condition_report?.hedera, '_blank');
        setHederaBtnClicked(false);
      }
    }
  }, [
    pdfSkipRequest,
    pdfModalVisible,
    errorPdf,
    isSuccessPdf,
    dataPdf,
    hederaBtnClicked,
  ]);

  const showReport = () => {
    const isComplete = !errorReport && isSuccessReport;

    if (isLoading) return <ContentPreloader />;
    if (isComplete && dataReport) {
      if (reportType === 'full') {
        return (
          <ReportFull
            data={dataReport}
            dataEnergy={dataEnergy}
            sourceCode={{ contractCode, errorContractCode }}
          />
        );
      } else {
        return <ReportSummary data={dataReport} dataEnergy={dataEnergy} />;
      }
    } else {
      return <S.NoDataAvailable>No data available</S.NoDataAvailable>;
    }
  };

  return (
    <S.Container stepAnimationVisible={stepAnimationVisible}>
      <ErrorReportModal
        isOpen={errorModalVisible}
        errorData={reqError}
        close={toggleErrorModal}
      />
      <DownloadPdfModal
        isOpen={pdfModalVisible}
        hederaLink={dataPdf?.condition_report?.hedera}
        close={() => setPdfModalVisible(false)}
      />

      {matchesMobile && !stepAnimationVisible && (
        <ActionButtons
          seeFullReport={seeFullReport}
          seeLiteReport={seeLiteReport}
          reportType={reportType}
          download={showPdfModal}
          handleHedera={handleHedera}
          isLoadingPdf={isLoadingPdf}
          isLoadingEnergy={dataEnergy.isLoading}
        />
      )}
      <S.Header>
        <S.HeaderTitle role="button" onClick={() => navigate(-1)}>
          {nftName || (nftTokenId && `#${nftTokenId}`)}
        </S.HeaderTitle>
        <div style={{ textAlign: 'right' }}>
          <h4>Condition Report</h4>
          <div style={{ color: 'var(--darkGrey)' }}>
            {formatDate(dataReport?.condition_report?.last_generation_date)}
          </div>
        </div>
      </S.Header>
      {stepAnimationVisible ? (
        <ReportPreloader />
      ) : (
        <>
          <S.MenuBar>
            <S.GradeLevel>
              <h3>Grade</h3>
              <Grade>{dataReport?.condition_report?.condition_letter}</Grade>
            </S.GradeLevel>

            <ActionButtons
              seeFullReport={seeFullReport}
              seeLiteReport={seeLiteReport}
              reportType={reportType}
              download={showPdfModal}
              handleHedera={handleHedera}
              isLoadingPdf={isLoadingPdf}
              isLoadingEnergy={dataEnergy.isLoading}
            />
          </S.MenuBar>

          {showReport()}
        </>
      )}
    </S.Container>
  );
};

export default NftReport;
