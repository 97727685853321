import { useParams } from 'react-router-dom';

import { ReactComponent as CodeIcon } from 'assets/images/icons/code.svg';
import PillButton from 'components/Buttons/PillButton';
import Grade from 'components/Grade';
import InfoIcon from 'components/InfoIcon';
import MuiAccordion from 'components/MuiAccordion';

import ConditionItem from '../../../ConditionItem';
import * as S from '../../styles';

const Code = ({
  categoryData,
  isExpanded,
  errorContractCode,
  toggleSourceCodeModal,
}) => {
  const chainId = useParams().chainId || '';
  const RightTitle = () => (
    <S.CodeButtonWrapper>
      {errorContractCode ? (
        <S.DisabledCodeBtn>
          Smart Contract Unavailable
          <InfoIcon info="Smart contract cannot be retrieved because it’s not open-source." />
        </S.DisabledCodeBtn>
      ) : (
        <PillButton
          label="View Smart Contract"
          variant="outlined-black"
          small
          onClick={toggleSourceCodeModal}
          disabled={errorContractCode}
        />
      )}

      <Grade size="md">{categoryData.condition_letter}</Grade>
    </S.CodeButtonWrapper>
  );

  return (
    <div id="code">
      <MuiAccordion
        id="code"
        isExpanded={isExpanded}
        title={
          <>
            <CodeIcon />
            <span>{categoryData.name}</span>
          </>
        }
        rightTitle={
          chainId === '1' || chainId === '3' ? (
            <RightTitle />
          ) : (
            <Grade size="md">{categoryData.condition_letter}</Grade>
          )
        }
      >
        {categoryData?.attributes && (
          <S.ConditionItems>
            {categoryData?.attributes.map((attribute, key) => (
              <ConditionItem attribute={attribute} key={key} />
            ))}
          </S.ConditionItems>
        )}

        {categoryData?.sub_categories &&
          categoryData?.sub_categories.map((category, key) => (
            <span key={key}>
              <S.SubCategoryTitle style={{ marginTop: '30px' }}>
                {category.name}
              </S.SubCategoryTitle>

              <S.ConditionItems>
                {category.attributes.map((attribute, key) => (
                  <ConditionItem attribute={attribute} key={key} />
                ))}
              </S.ConditionItems>
            </span>
          ))}
      </MuiAccordion>
    </div>
  );
};

export default Code;
