import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ToastContainer, toast } from 'react-toastify';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as EtherIcon } from 'assets/images/icons/ether.svg';
import { ReactComponent as HederaIcon } from 'assets/images/icons/hedera.svg';
import { ReactComponent as LinkIcon } from 'assets/images/icons/link.svg';
import { ReactComponent as PolygonIcon } from 'assets/images/icons/polygon.svg';
import ToolTip from 'components/ToolTip';
import { formatText } from 'utils';

const icons = {
  copy: ContentCopyIcon,
  ethereum: EtherIcon,
  hedera: HederaIcon,
  polygon: PolygonIcon,
};

interface PillAddressButton {
  label: string;
  isLink?: boolean;
  href?: string;
  icon?: 'copy' | 'ethereum' | 'hedera' | 'polygon';
  iconPositionRight?: boolean;
  showCopyIcon?: boolean; //Display the copy icon and the selected icon
}

//TODO: rename the component, move the css out of this file

const PillAddressButton = ({
  label,
  isLink,
  href,
  icon,
  iconPositionRight,
  showCopyIcon,
}: PillAddressButton) => {
  const [title, setTitle] = useState('Copy');
  const copyContent = () => {
    navigator.clipboard.writeText(label).then(
      function () {
        setTitle('Copied!');
      },
      function () {
        toast.error('Could not copy text');
      }
    );
  };

  if (isLink) {
    return (
      <S.Link href={href}>
        {formatText(label)} <LinkIcon />
      </S.Link>
    );
  }
  const SelectedIcon = icon !== undefined ? icons[icon] : () => null;

  return (
    <>
      <ToolTip title={title} onOpen={() => setTitle('Copy')}>
        <S.Button
          role="button"
          onClick={copyContent}
          iconPositionRight={iconPositionRight}
        >
          <SelectedIcon /> {formatText(label)}{' '}
          {showCopyIcon && <ContentCopyIcon />}
        </S.Button>
      </ToolTip>
      <ToastContainer />
    </>
  );
};

/* ==========================================================================
  Styled-Components
  ========================================================================== */
const S: { [key: string]: any } = {};

const sharedStyle = css`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 10px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 4px 16px 4px 16px;
  svg {
    fill: black;
  }

  &:hover {
    background-color: black;
    color: white;
    svg {
      fill: white;
    }
  }
`;

S.Link = styled.a`
  ${sharedStyle}
  font-weight: 600;
`;

interface Button {
  iconPositionRight: boolean;
}

S.Button = styled.span<Button>`
  ${sharedStyle}
  display: flex;
  flex-direction: ${({ iconPositionRight }) =>
    iconPositionRight ? 'row-reverse' : 'row'};
`;

export default PillAddressButton;
