import EngineeringIcon from '@mui/icons-material/Engineering';
import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

const UnderConstruction = () => {
  return (
    <S.Continer>
      <h1>
        <EngineeringIcon style={{ fontSize: '50px', marginRight: '20px' }} />{' '}
        Under construction
      </h1>
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 88px);

  h1 {
    display: flex;
    align-items: center;
    font-weight: 200;
    ${mediaQueries.sm} {
      font-size: 2rem;
    }
  }
`;

export default UnderConstruction;
