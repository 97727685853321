import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

interface Container {
  currentChain: string;
}

export const Continer = styled.div<Container>`
  display: grid;
  grid-template-rows: ${({ currentChain }) =>
    currentChain ? '35px auto 1fr' : '35px 1fr auto'};
  margin-top: 88px;
  padding: 64px;
  box-sizing: border-box;
  height: auto;
  min-height: calc(100vh - 88px);
  overflow: auto;
  background-color: black;
  color: white;
  text-align: center;

  p {
    color: var(--darkGrey);
  }

  ${mediaQueries.xs} {
    padding: 24px;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const CloseButton = styled.span`
  display: flex;
  align-items: center;
  color: var(--darkGrey);
  font-size: 12px;
  svg {
    color: white;
  }
  cursor: pointer;
`;

export const BackButton = styled.span`
  display: flex;
  align-items: center;
  color: var(--darkGrey);
  font-size: 12px;
  svg {
    color: white;
  }
  cursor: pointer;
`;

export const Main = styled.main`
  margin-top: 42px;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueries.xs} {
    grid-gap: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
`;
