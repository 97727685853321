import { ReactComponent as BookCheckIcon } from 'assets/images/icons/book-check.svg';
import Grade from 'components/Grade';
import MuiAccordion from 'components/MuiAccordion';
import { toggleSubscriptionModal } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';

import * as S from '../../styles';

const Provenance = ({ isExpanded }) => {
  const dispatch = useAppDispatch();

  const tooggleSubscription = () => {
    dispatch(toggleSubscriptionModal());
  };

  return (
    <div id="provenance">
      <MuiAccordion
        id="provenance"
        isExpanded={isExpanded}
        title={
          <>
            <BookCheckIcon style={{ fill: 'black' }} />
            <span>Provenance</span>
          </>
        }
        rightTitle={<Grade size="md">{undefined}</Grade>}
      >
        <p style={{ margin: 0 }}>
          We have exciting ideas about how this feature and are actively working
          to develop and integrate it into the platform.
        </p>
        <p>
          Please{' '}
          <S.HightLighted onClick={tooggleSubscription} role="button">
            stay in touch
          </S.HightLighted>{' '}
          for updates.
        </p>
      </MuiAccordion>
    </div>
  );
};

export default Provenance;
