import styled, { css } from 'styled-components/macro';

const sm = css`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  font-size: 14px;
  border-radius: 10px;
`;

const md = css`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  font-size: 20px;
  border-radius: 12px;
`;

const lg = css`
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  font-size: 38px;
`;

interface IContainer {
  bgColor: string;
  size?: 'sm' | 'md' | 'lg';
}

export const Continer = styled.div<IContainer>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${({ bgColor }) => bgColor || 'var(--error)'};
  color: white;
  font-weight: 500;

  ${({ size }) => {
    switch (size) {
      case 'sm':
        return sm;
      case 'md':
        return md;
      default:
        return lg;
    }
  }}
`;

interface Dots {
  size?: 'sm' | 'md' | 'lg';
}

export const Dots = styled.span<Dots>`
  font-size: 64px;
  line-height: 0;
  position: relative;
  //bottom: 0;

  ${({ size }) => {
    if (size === 'md') {
      return css`
        font-size: 32px;
        //bottom: 0;
      `;
    }

    if (size === 'sm') {
      return css`
        font-size: 22px;
        //bottom: 6px;
      `;
    }
  }}
`;

interface Icon {
  size?: string;
  badgeToBottom?: boolean;
}

export const Badge = styled.div<Icon>`
  svg {
    //Default large
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    ${({ size, badgeToBottom }) => {
      if (!size && badgeToBottom) {
        //Large
        return css`
          top: auto;
          bottom: -14px;
        `;
      }
      if (size === 'md') {
        if (badgeToBottom) {
          return css`
            width: 14px;
            top: auto;
            bottom: -17px;
            right: -5px;
          `;
        }
        return css`
          width: 14px;
          top: -17px;
          right: -5px;
        `;
      }
    }}
  }
`;
