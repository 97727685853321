import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;

  ${mediaQueries.sm} {
    height: 100%;
    align-items: end;
  }

  h2 {
    font-size: 40px;
    ${mediaQueries.sm} {
      font-size: 32px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 48px;

  ${mediaQueries.sm} {
    flex-direction: column;
  }
`;
