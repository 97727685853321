import { useState, useEffect } from 'react';

import SwitchSelector from 'react-switch-selector';

import { closeMobileMenu } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';

import TokenIdForm from './components/TokenIdForm';
import UrlForm from './components/UrlForm';
import * as S from './styles';

const EthBox = ({ tooggleSearchNft, chainId }) => {
  const [currentForm, setCurrentForm] = useState('url');
  const chainName = chainId === 1 ? 'ETH' : 'Polygon';

  const dispatch = useAppDispatch();
  // Switch options
  const options = [
    {
      label: 'URL',
      value: 'url',
    },
    {
      label: 'Token ID',
      value: 'tokenId',
    },
  ];

  const onSwitchChange = (newValue) => {
    setCurrentForm(newValue);
  };

  useEffect(() => {
    dispatch(closeMobileMenu());
  }, []);

  return (
    <>
      <S.Main>
        <h2>Grade {chainName} NFTs</h2>
        <p>
          Select your preferred method and enter the information to retrieve
          your NFT and grade it.
          <small style={{ display: 'block', marginTop: '10px' }}>
            We support ERC-721 & ERC-1155, and OpenSea and Rarible
          </small>
        </p>
        <S.SwitchContainer>
          <SwitchSelector
            onChange={onSwitchChange}
            options={options}
            initialSelectedIndex={0}
            backgroundColor={'#202020'}
            fontColor={'#f5f6fa'}
            selectedBackgroundColor="white"
            selectedFontColor="black"
            wrapperBorderRadius={100}
            optionBorderRadius={100}
          />
        </S.SwitchContainer>
      </S.Main>

      {currentForm === 'url' ? (
        <UrlForm tooggleSearchNft={tooggleSearchNft} chainId={chainId} />
      ) : (
        <TokenIdForm tooggleSearchNft={tooggleSearchNft} chainId={chainId} />
      )}
    </>
  );
};

export default EthBox;
