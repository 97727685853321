import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

const CookiesBanner = ({ setCookiesBannerVisible }) => {
  return (
    <S.Continer>
      <S.TextContiner>
        <CookieOutlinedIcon />
        <p>
          By using this website, you agree to our use of cookies. We use cookies
          to provide you with a great experience and to help our website run
          effectively.
        </p>
      </S.TextContiner>

      <S.CloseRoundedIcon
        onClick={() => setCookiesBannerVisible(false)}
        role="button"
      />
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 2200;
  background-color: #000000d1;
  color: white;
  padding: 0 64px 0 64px;
  align-items: center;

  ${mediaQueries.md} {
    padding: 0 16px 0 16px;
    flex-direction: row;
  }
`;

S.TextContiner = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-right: 5px;
`;

S.CloseRoundedIcon = styled(CloseRoundedIcon)`
  cursor: pointer;
`;

export default CookiesBanner;
