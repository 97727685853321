import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

const ArtistStatement = () => {
  return (
    <S.Container>
      <S.Content>
        <h1>Artist Statement</h1>
        <hr />
        <p>
          As a sculptor and the recipient of life-saving diagnostic medicine, I
          am acutely aware that the strength and durability of things can rarely
          be discerned by merely looking at them.
        </p>

        <p>
          In this early moment, NFTs might best be characterized as synthetic
          organisms composed of code and media - yet to become fully realized
          subjects of anatomic scrutiny or illustration. This effectively
          renders the technology mysterious, ripe for abuse, difficult and, at
          times, dangerous to engage.
        </p>

        <p>
          While there is enormous potential for NFTs to serve the best interests
          of artists, artist estates, foundations, museums, galleries, and
          auction houses among others - tools to validate acts of integrity from
          artifice and to distinguish the work of con-artists from the sincere
          are needed.
        </p>

        <p>In order to do no harm - first, we must learn to mint no harm.</p>

        <p>
          NFT Grade proposes a condition report for NFTs akin to the traditional
          methods used by registrars and conservators of physical artworks. It
          is intended to be a building block of trust for those who create and
          transact NFTs made possible by accurately translating the traits of
          blockchain and tokens into thorough yet easy-to-understand grades.
        </p>

        <p>
          This basic act of comparison and equivalency - between NFTs themselves
          and between NFTs and their physical art-object counterparts - advances
          a position that a synthesis of tradition and innovation can be
          accomplished respectfully and to the benefit of those invested in
          labor, experience, and expertise in both communities.
        </p>

        <p>
          I think it&apos;s important to note here that I don&apos;t regard NFT
          Grade as an artwork because it proposes a solution. Interrogation has
          led to intervention. Experience tells me that this activity aligns
          more closely with &apos;social practices&apos; or &apos;social
          engagements.&apos; It is perhaps para-Artistic, but not Art itself.
        </p>

        <p>
          Neither Art nor NFT and their attendant communities deserve disruption
          or revolution. Where Art has failed to fully articulate diversity as
          visibility, participation, and agency; or to preserve a connection
          between artworks&apos; collection and its creators, blockchain and
          NFTs have actively perverted the role of institutions, systems of
          regulation, and identity. Neither have meaningfully addressed their
          environmental impacts.
        </p>

        <p>
          Tomorrow will be both Artistic and Tokenized. While I don&apos;t think
          we need each other - or at the moment particularly want each other -
          dialogue, engagement, and negotiation remains the path forward.
        </p>

        <hr style={{ marginBottom: '24px' }} />

        <S.BottomRow>
          <span>Sean Shim-Boyle</span>
          <span>February 2022</span>
        </S.BottomRow>
      </S.Content>
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.div`
  position: relative;
  min-height: 500px;
  background-color: var(--pale);

  hr {
    height: 1px;
    background-color: var(--lightGrey);
    border: none;
    margin: 48px 0 48px 0;
  }

  strong {
    font-weight: 500;
  }
`;

S.Content = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 96px;
  background-color: white;

  ${mediaQueries.sm} {
    padding: 64px 24px 64px 24px;
  }
`;

S.BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--darkGrey);
`;

export default ArtistStatement;
