import styled from 'styled-components/macro';

const LevelBar = ({ value }) => {
  return (
    <S.Continer>
      <S.Bar value={value} />
      <S.Value>{value?.toLocaleString()} kgs</S.Value>
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

interface Bar {
  value: number;
}

S.Bar = styled.div<Bar>`
  height: 24px;
  width: ${({ value }) => (value * 100) / 12000}%;
  background-color: ${({ value }) => {
    if (value > 1800) {
      return '#ff4f4f';
    } else if (value > 1300) {
      return '#ff8e4f';
    } else if (value > 1200) {
      return '#ffb31f';
    }
    return 'green';
  }};
`;

S.Value = styled.div`
  height: 24px;
  min-width: 100px;
  margin-left: 8px;
`;

export default LevelBar;
