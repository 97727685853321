import { useState, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';

import Grade from 'components/Grade';
import { scrollToHtmlEl } from 'utils';

import CodeModal from './components/CodeModal';
import CriteriaReport from './components/CriteriaReport';
import NftDetails from './components/NftDetails';
import * as S from './styles';

const ReportFull = ({ data, dataEnergy, sourceCode }) => {
  const { contractCode, errorContractCode } = sourceCode;
  const [codeModalVisible, setCodeModalVisible] = useState(false);

  const [search] = useSearchParams();
  const reportType = search.get('category');
  const matchesMobile = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    if (reportType) {
      scrollToHtmlEl(`#${reportType}`, 88);
    }
  }, [reportType, matchesMobile]);

  const toggleSourceCodeModal = (e) => {
    e.stopPropagation();
    setCodeModalVisible((prevState) => !prevState);
  };

  return (
    <S.Continer>
      {!errorContractCode && (
        <CodeModal
          isOpen={codeModalVisible}
          close={toggleSourceCodeModal}
          bgColor="black"
          info={true}
          contractCode={contractCode}
        />
      )}

      {matchesMobile && (
        <S.GradeLevel>
          <h3>Grade</h3>
          <Grade>{data?.condition_report?.condition_letter}</Grade>
        </S.GradeLevel>
      )}

      <NftDetails data={data} />

      <CriteriaReport
        data={data}
        dataEnergy={dataEnergy}
        reportType={reportType}
        errorContractCode={errorContractCode}
        toggleSourceCodeModal={toggleSourceCodeModal}
      />
    </S.Continer>
  );
};

export default ReportFull;
