import { useState, useEffect } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import PillButton from 'components/Buttons/PillButton';
import { closeMobileMenu } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';

import Eth from './Eth';
import Hedera from './Hedera';
import * as S from './styles';

const SearchNftBox = ({ tooggleSearchNft }) => {
  const [currentChain, setCurrentChain] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(closeMobileMenu());
  }, []);

  return (
    <S.Continer currentChain={currentChain}>
      <S.NavBar>
        <S.CloseButton role="button" onClick={tooggleSearchNft}>
          Close <CloseRoundedIcon />
        </S.CloseButton>
        {currentChain && (
          <S.BackButton role="button">
            <ArrowBackIosNewIcon
              onClick={() => setCurrentChain('')}
              style={{ fontSize: '16px' }}
            />
          </S.BackButton>
        )}
      </S.NavBar>

      {!currentChain && (
        <S.Main>
          <div>
            <h2>Select Chain</h2>
            <p>
              Select your NFT’s blockchain <br /> to retrieve the NFT and grade
              it.
            </p>
          </div>

          <S.ButtonsContainer>
            <PillButton
              label="Hedera"
              variant="white"
              width="100%"
              onClick={() => setCurrentChain('hedera')}
            />
            <PillButton
              label="ETH"
              variant="white"
              width="100%"
              onClick={() => setCurrentChain('eth')}
            />
            <PillButton
              label="Polygon"
              variant="white"
              width="100%"
              onClick={() => setCurrentChain('polygon')}
            />
          </S.ButtonsContainer>

          <p>
            NFT Grade supports <br />
            Ethereum, Polygon, & Hedera Mainnet <br />
            (more coming soon!)
          </p>
        </S.Main>
      )}

      {currentChain === 'hedera' && (
        <Hedera tooggleSearchNft={tooggleSearchNft} />
      )}

      {currentChain === 'eth' && (
        <Eth tooggleSearchNft={tooggleSearchNft} chainId={1} />
      )}

      {currentChain === 'polygon' && (
        <Eth tooggleSearchNft={tooggleSearchNft} chainId={3} />
      )}
    </S.Continer>
  );
};

export default SearchNftBox;
