import { ReactComponent as EtherRoundedIcon } from 'assets/images/icons/ether-rounded.svg';
import { ReactComponent as HederaRoundedIcon } from 'assets/images/icons/hedera-rounded.svg';
import { ReactComponent as PolygonIcon } from 'assets/images/icons/polygon.svg';
import PillAddressButton from 'components/Buttons/PillAddressButton';
import PreviewImage from 'components/PreviewImage';
import Show from 'components/Show';
import { formatDate } from 'utils';

import * as S from '../../styles';
import { SectionTitle, SectionContent } from '../CriteriaReport/styles';

const NftDetails = ({ data }) => {
  const chainId = data.nft?.chain_id;
  const chainName =
    chainId === 1 ? 'ethereum' : chainId === 2 ? 'hedera' : 'polygon';
  const tokenType = data.nft.token_type;

  return (
    <>
      <SectionTitle>NFT Details</SectionTitle>

      <SectionContent>
        <S.Details>
          <S.LeftDetail>
            {data?.nft?.description && (
              <div>
                <S.DetailTitle>Description</S.DetailTitle>
                {data.nft.description}
              </div>
            )}

            <S.GridDetails>
              <S.Detail>
                <S.DetailTitle>Chain</S.DetailTitle>

                <S.ChainDetail>
                  {chainName === 'ethereum' && (
                    <>
                      <EtherRoundedIcon />
                      Ethereum Mainnet
                    </>
                  )}

                  {chainName === 'hedera' && (
                    <>
                      <HederaRoundedIcon />
                      Hedera Mainnet
                    </>
                  )}

                  {chainName === 'polygon' && (
                    <>
                      <PolygonIcon />
                      Polygon Mainnet
                    </>
                  )}
                </S.ChainDetail>
              </S.Detail>

              <S.Detail>
                <S.DetailTitle style={{ display: 'flex', gap: '10px' }}>
                  {chainName === 'ethereum' || chainName === 'polygon'
                    ? 'Smart Contract Address'
                    : 'Token ID'}
                </S.DetailTitle>
                <strong>
                  <PillAddressButton
                    label={data.nft?.contract_address}
                    icon="copy"
                    iconPositionRight
                  />
                </strong>
              </S.Detail>

              <S.Detail>
                <S.DetailTitle>
                  {chainName === 'ethereum' || chainName === 'polygon'
                    ? tokenType === 'ERC-1155'
                      ? 'Batch ID '
                      : 'Token ID '
                    : 'Serial #'}
                </S.DetailTitle>
                <strong>
                  <PillAddressButton
                    label={`#${data.nft?.token_id}`}
                    icon="copy"
                    iconPositionRight
                  />
                </strong>
              </S.Detail>

              <S.Detail>
                <S.DetailTitle>
                  {chainName === 'ethereum' || chainName === 'polygon'
                    ? 'Contract Standard'
                    : 'Token Standard'}
                </S.DetailTitle>
                <strong>{data.nft?.token_type}</strong>
              </S.Detail>

              <S.Detail>
                <S.DetailTitle>Created by</S.DetailTitle>
                <S.DetailContent>
                  {data.nft?.ens_created_by}
                  <PillAddressButton
                    label={data.nft?.created_by}
                    icon={chainName}
                    showCopyIcon
                  />
                </S.DetailContent>
              </S.Detail>

              <S.Detail>
                <S.DetailTitle>Mint Timestamp</S.DetailTitle>
                <strong>{formatDate(data.nft?.mint_timestamp)}</strong>
              </S.Detail>

              <Show
                when={
                  tokenType !== 'ERC-1155' && data.nft?.owner[0]?.ens_owner_name
                }
              >
                <S.Detail>
                  <S.DetailTitle>Owned by</S.DetailTitle>
                  <strong
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    {data.nft?.owner[0]?.ens_owner_name}
                    <PillAddressButton
                      label={data.nft?.owner[0]?.owner_name}
                      icon={chainName}
                      showCopyIcon
                    />
                  </strong>
                </S.Detail>
              </Show>
            </S.GridDetails>
          </S.LeftDetail>

          <S.RightDetail>
            {data.nft?.mediaType === 'video' ? (
              <video
                key={data.nft._id}
                src={data.nft?.image_url}
                autoPlay
                muted
                controls={false}
                loop
                style={{
                  width: '80%',
                  maxHeight: '60vh',
                }}
              >
                <p>
                  Your browser doesn&apos;t support HTML5 video. Here is a{' '}
                  <a
                    href={data.nft?.image_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    link to the video
                  </a>{' '}
                  instead.
                </p>
              </video>
            ) : (
              <PreviewImage src={data.nft?.image_url} isPageMode />
            )}
          </S.RightDetail>
        </S.Details>
      </SectionContent>
    </>
  );
};

export default NftDetails;
