import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--pale);

  ${mediaQueries.md} {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
  }
`;

export const LeftContent = styled.div`
  padding: 48px 64px;

  ${mediaQueries.md} {
    grid-row-start: 2;
    padding: 0 0;
  }
`;

export const RightContent = styled.div`
  padding: 48px 5% 48px 0;
  ${mediaQueries.md} {
    grid-row-start: 1;
    display: flex;
    justify-content: center;
    padding: 48px 5% 48px 5%;
  }
  ${mediaQueries.xs} {
    padding: 0;
  }
`;

export const GradeLevel = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  ${mediaQueries.md} {
    padding: 24px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const Video = styled.video`
  max-height: 64vh;
`;
