import styled from 'styled-components/macro';

import heroBg from 'assets/images/hero-bg.png';
import { mediaQueries } from 'styles/media';

export const Container = styled.div`
  background-image: url(${heroBg}),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(241, 241, 241, 1) 60%,
      rgba(166, 166, 166, 1) 100%
    );

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  ${mediaQueries.md} {
    background-image: url(${heroBg}),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(241, 241, 241, 1) 60%,
        rgba(166, 166, 166, 1) 100%
      );
  }
`;

export const Content = styled.div`
  min-height: calc(100vh - 88px);
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  padding: var(--desktopViewPadding);
  box-sizing: border-box;

  ${mediaQueries.md} {
    display: flex;
    flex-direction: column-reverse;
    padding: var(--mobileViewPadding);
    justify-content: center;
  }
`;

export const LeftCol = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaQueries.md} {
    flex-direction: column;
  }
`;

export const TopText = styled.div`
  height: min-content;
  display: flex;
  gap: 36px;
  flex-direction: column;

  ${mediaQueries.md} {
    gap: 22px;
  }
`;

export const BottomText = styled.div`
  padding: var(--desktopViewPadding);
  padding-top: 32px;
  padding-bottom: 56px;

  p {
    font-size: 32px;
    margin: 0;

    ${mediaQueries.md} {
      font-size: 28px;
    }
  }
  span {
    color: rgba(0, 0, 0, 0.56);
  }

  ${mediaQueries.md} {
    padding: var(--mobileViewPadding);
    padding-bottom: 32px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
`;

export const BigTitle = styled.h2`
  font-size: 88px;
  font-weight: 400;
  line-height: 120%;

  ${mediaQueries.lg} {
    font-size: 68px;
  }

  ${mediaQueries.md} {
    font-size: 56px;
    font-weight: 500;

    br {
      display: none;
    }
  }

  ${mediaQueries.sm} {
    font-size: 48px;
  }

  ${mediaQueries.xss} {
    font-size: 38px;
  }
`;

export const Subtitle = styled.span`
  color: rgba(0, 0, 0, 0.56);
`;

export const RightCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  width: 80%;

  ${mediaQueries.lg} {
    width: 100%;
  }

  ${mediaQueries.md} {
    width: 70%;
  }

  ${mediaQueries.sm} {
    width: 100%;
  }
`;
