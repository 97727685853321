import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components/macro';

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    padding: '10px',
  },
}));

interface ToolTip {
  children: JSX.Element;
  title: string;
  [rest: string]: any;
}

const ToolTip = ({ children, title, ...rest }: ToolTip) => {
  return (
    <>
      <BlackTooltip {...rest} title={title} arrow>
        {children}
      </BlackTooltip>
    </>
  );
};

export default ToolTip;
