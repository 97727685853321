import { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import ContentPreloader from 'components/ContentPreloader';
import ImageSlider from 'components/ImageSlider';
import { useGetGalleryQuery } from 'services';
import { mediaQueries } from 'styles/media';

import PreviewDetails from '../components/PreviewDetails';

const GalleryPreview = () => {
  const navigate = useNavigate();
  const { data, error, isLoading, isSuccess } = useGetGalleryQuery('');
  const [initialSlide, setInitialSlide] = useState<null | number>(null); //Initial slide number
  const [currentNft, setCurrentNft] = useState<null | any>(null); //Selected nft from the slider
  const paramId = useParams().id || ''; //Url param

  const findIndex = (paramId: string, data) => {
    const result = data.findIndex((el) => el._id === paramId);
    return result > 0 ? result : 0;
  };

  //Initial slider - index number
  useEffect(() => {
    if (!isLoading && !error) {
      setInitialSlide(findIndex(paramId, data));
    }
  }, [isLoading]);

  //Current slider "NFT displayed" data
  useEffect(() => {
    if (!isLoading && !error) {
      const index = findIndex(paramId, data);
      setCurrentNft(data[index]);
    }
  }, [isLoading, paramId]);

  const updateUrlId = (param: string) => {
    navigate(`/nft/preview/${param}`, { replace: true });
  };

  const generateReport = () => {
    if (currentNft !== null) {
      const address = currentNft.contract_address;
      const tokenId = currentNft.token_id;
      const chainId = currentNft.chain_id;
      navigate(`/nft/report/${address}/${tokenId}/${chainId}`);
    }
  };

  return (
    <S.Continer>
      <S.MediaContainer>
        {initialSlide === null ? (
          <ContentPreloader />
        ) : (
          <ImageSlider
            data={data}
            initialSlide={initialSlide}
            updateUrlId={updateUrlId}
          />
        )}
      </S.MediaContainer>
      {isSuccess && currentNft && (
        <PreviewDetails
          data={currentNft}
          generateReport={generateReport}
          searchButtonVisible={false}
        />
      )}
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.main`
  display: grid;
  grid-template-columns: 66% 34%;
  min-height: calc(100vh - 88px);

  ${mediaQueries.md} {
    grid-template-columns: 100%;
  }
`;

S.MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--pale);
  ${mediaQueries.sm} {
    padding: 0;
  }
`;

export default GalleryPreview;
