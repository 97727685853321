import { useState } from 'react';

import styled, { css } from 'styled-components/macro';

import checkGreenIcon from 'assets/images/icons/check-green.svg';
import errorIcon from 'assets/images/icons/error.svg';
import InfoIcon from 'components/InfoIcon';
//import arrowIcon from 'assets/images/icons/arrow-circle-black.svg';
//import arrowIconViolet from 'assets/images/icons/arrow-circle-violet.svg';

interface ITextField {
  disabled?: boolean;
  errorMessage?: string;
  width?: string;
  margin?: string;
  label?: any; //TODO: replace any
  labelCentered?: boolean;
  name?: string;
  touched?: boolean;
  colorMode?: 'light' | 'dark';
  value?: string;
  info?: string;
  [rest: string]: any;
}

const TextField = ({
  label,
  labelCentered,
  disabled,
  touched,
  colorMode,
  width,
  margin,
  errorMessage,
  name,
  value = '',
  info,
  ...rest
}: ITextField) => {
  const [didFocus, setDidFocus] = useState(false);
  const showFeedback = (didFocus && value.trim().length) || touched;
  return (
    <S.Container width={width} margin={margin}>
      {label && (
        <S.LabelContainer labelCentered={labelCentered}>
          <S.Label htmlFor={name}>{label}</S.Label>
          {info && <InfoIcon info={info} />}
        </S.LabelContainer>
      )}
      <S.InputText
        {...rest}
        name={name}
        value={value}
        disabled={disabled}
        colorMode={colorMode}
        showFeedback={showFeedback}
        width={width}
        errorMessage={errorMessage}
        onFocus={() => setDidFocus(true)}
      />
      {showFeedback && <S.ErrorContainer>{errorMessage}</S.ErrorContainer>}
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.div<ITextField>`
  position: relative;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

S.ErrorInput = css`
  border: 1px solid var(--error);
  background: url(${errorIcon}) no-repeat;
  background-position: 95% 15px;

  :focus-visible {
    border: 1px solid var(--error);
    box-shadow: none;
    outline: none;
  }
`;

S.SuccessInput = css`
  border: 1px solid var(--success);
  background: url(${checkGreenIcon}) no-repeat;
  background-position: 95% 15px;

  :focus-visible {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--success);
  }
`;

S.InputText = styled.input<ITextField>`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: 58px;
  border: 1px solid var(--darkGrey);
  border-radius: 8px;
  background: white;
  background-position: 95% 15px;
  font-size: 1rem;
  padding: 0 24px 0 24px;

  ::placeholder {
    color: var(--darkGrey);
  }

  :focus-visible {
    border: 1px solid var(--secundary);
    box-shadow: none;
    outline: none;
  }
  ${(props) => {
    if (props.showFeedback) {
      if (props.errorMessage) {
        return S.ErrorInput;
      }
      return S.SuccessInput;
    }
  }};
`;

S.ErrorContainer = styled.div`
  padding: 5px;
  font-size: 12px;
  color: var(--error);
  text-align: left;
`;

interface ILabel {
  labelCentered?: boolean;
}

S.LabelContainer = styled.div<ILabel>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.labelCentered ? 'center' : 'left')};
  gap: 6px;
  margin-bottom: 8px;
`;

S.Label = styled.label`
  display: block;
  font-size: 12px;
`;

export default TextField;
