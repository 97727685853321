import { createSlice } from '@reduxjs/toolkit';

export interface IGlobalSlice {
  ui: {
    searchDrawerVisibility: boolean;
    mobileMenuVisibility: boolean;
    subscriptionModalVisibility: boolean;
  };
}

const initialState: IGlobalSlice = {
  ui: {
    searchDrawerVisibility: false,
    mobileMenuVisibility: false,
    subscriptionModalVisibility: false,
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleDrawer: (state) => ({
      ...state,
      ui: {
        ...state.ui,
        searchDrawerVisibility: !state.ui.searchDrawerVisibility,
      },
    }),
    toggleMobileMenu: (state) => ({
      ...state,
      ui: {
        ...state.ui,
        mobileMenuVisibility: !state.ui.mobileMenuVisibility,
      },
    }),
    closeMobileMenu: (state) => ({
      ...state,
      ui: {
        ...state.ui,
        mobileMenuVisibility: false,
      },
    }),
    toggleSubscriptionModal: (state) => ({
      ...state,
      ui: {
        ...state.ui,
        subscriptionModalVisibility: !state.ui.subscriptionModalVisibility,
      },
    }),
  },
});

const { actions, reducer } = globalSlice;
export const {
  toggleDrawer,
  toggleMobileMenu,
  closeMobileMenu,
  toggleSubscriptionModal,
} = actions;
export default reducer;
