import { useEffect } from 'react';

import { closeMobileMenu } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';

import HederaForm from './components/HederaForm';
import * as S from './styles';

const HederaBox = ({ tooggleSearchNft }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(closeMobileMenu());
  }, []);

  return (
    <>
      <S.Main>
        <h2>Grade Hedera NFTs</h2>
        <S.Text>
          Select your preferred method and enter the information to retrieve
          your NFT and grade it.
          <br />
          <small style={{ display: 'block', marginTop: '10px' }}>
            We support Hedera NFT HIP-17 or greater.
          </small>
        </S.Text>
      </S.Main>

      <HederaForm tooggleSearchNft={tooggleSearchNft} />
    </>
  );
};

export default HederaBox;
