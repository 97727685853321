import PillButton from 'components/Buttons/PillButton';

import * as S from './styles';

type ActionButtons = {
  download?: () => void;
  handleHedera?: () => void;
  seeFullReport: () => void;
  seeLiteReport: () => void;
  reportType: string;
  isLoadingPdf: boolean;
  isLoadingEnergy: boolean;
};

const ActionButtons = ({
  download,
  handleHedera,
  seeFullReport,
  seeLiteReport,
  reportType,
  isLoadingPdf,
  isLoadingEnergy,
}: ActionButtons) => {
  return (
    <S.ButtonsContainer>
      {/* <PillButton
        label="See on Hedera"
        variant="outlined-black"
        icon="hedera"
        onClick={handleHedera}
        //loading={isLoadingPdf || isLoadingEnergy}
        disabled={isLoadingPdf || isLoadingEnergy}
      /> */}
      <PillButton
        label="Download Full Report"
        variant="outlined-black"
        icon="download"
        onClick={download}
        disabled={isLoadingEnergy}
      />
      <PillButton
        label={reportType === 'full' ? 'See Report Summary' : 'See Full Report'}
        variant="secundary"
        icon={reportType === 'full' ? 'arrowUpward' : 'add'}
        onClick={reportType === 'full' ? seeLiteReport : seeFullReport}
      />
    </S.ButtonsContainer>
  );
};

export default ActionButtons;
