import { useParams } from 'react-router-dom';

import GalleryPreview from './GalleryPreview';
import SearchPreview from './SearchPreview';

const NftPreview = () => {
  const id = useParams().id;
  return <>{id ? <GalleryPreview /> : <SearchPreview />}</>;
};

export default NftPreview;
