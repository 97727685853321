import { useRef } from 'react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LeftArrow from 'assets/images/icons/left-arrow.svg';
import RightArrow from 'assets/images/icons/right-arrow.svg';
import PreviewImage from 'components/PreviewImage';

import * as S from './styles';

const ImageSlider = ({ data, initialSlide, updateUrlId }) => {
  const slider = useRef<Slider>();
  const next = () => {
    slider.current.slickNext();
  };
  const prev = () => {
    slider.current.slickPrev();
  };

  const slideSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: false,
    speed: 500,
    draggable: false,
    slide: 'header',
    beforeChange: (current: number, next: number) => {
      updateUrlId(data[next]._id);
    },
    appendDots: (dots) => (
      <div>
        <li>
          <S.SliderArrow src={LeftArrow} alt="left-arrow" onClick={prev} />
        </li>
        {dots}
        <li>
          <S.SliderArrow src={RightArrow} alt="right-arrow" onClick={next} />
        </li>
      </div>
    ),
  };

  return (
    <S.Continer>
      <Slider {...slideSettings} ref={slider} initialSlide={initialSlide}>
        {data.map((nft) =>
          nft.mediaType === 'video' ? (
            <S.Video
              key={nft._id}
              src={`${nft.image_url}#t=0.5`}
              autoPlay
              muted
              loop
              webkit-playsinline
              playsInline
              controls={false}
              preload="metadata"
            >
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a{' '}
                <a href={data.nft?.image_url} target="_blank" rel="noreferrer">
                  link to the video
                </a>{' '}
                instead.
              </p>
            </S.Video>
          ) : (
            <PreviewImage key={nft._id} src={nft.image_url} />
          )
        )}
      </Slider>
    </S.Continer>
  );
};

export default ImageSlider;
