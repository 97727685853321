import styled from 'styled-components/macro';

import { mediaQueries, deviceSizes } from 'styles/media';

export const Container = styled.div`
  position: relative;
  min-height: 500px;
  background-color: var(--pale);

  hr {
    height: 1px;
    background-color: var(--lightGrey);
    border: none;
    margin: 48px 0 48px 0;
  }

  strong {
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 96px;
  background-color: white;

  ${mediaQueries.sm} {
    padding: 64px 24px 64px 24px;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--darkGrey);
`;

export const Grid = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const GridTrack = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr 150px;

  @media (min-width: ${deviceSizes.md}) {
    &:first-child > div:first-child {
      border-radius: 8px 0 0 0;
    }

    &:first-child > div:last-child {
      border-radius: 0 8px 0 0;
    }

    &:last-child > div:first-child {
      border-radius: 0 0 0 8px;
    }

    &:last-child > div:last-child {
      border-radius: 0 0 8px 0;
    }

    &:last-child > div {
      border-bottom: 1px solid var(--grey);
    }

    &:first-child div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  ${mediaQueries.md} {
    &:first-child {
      display: none;
    }
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
`;

export const GridCell = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: solid;
  border-width: 1px 0 0 1px;
  border-color: var(--grey);

  &:last-child {
    border-right: 1px solid var(--grey);
  }

  ${mediaQueries.md} {
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--grey);

    &:first-child {
      border-bottom: 0;
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-top: 0;
      border-radius: 0 0 8px 8px;
    }
  }

  ul {
    padding-left: 24px;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export const CellTitle = styled.p`
  display: none;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  font-weight: 500;

  ${mediaQueries.md} {
    display: block;
  }
`;

export const Row = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const BottomDate = styled.div`
  color: var(--darkGrey);
  text-align: right;
`;
