import Grade from 'components/Grade';
import InfoIcon from 'components/InfoIcon';
import { capitalize } from 'utils';

import * as S from './styles';

const ConditionItem = ({ attribute }) => {
  const formatAttribute = (attributeName, attributeValue) => {
    switch (attributeName) {
      case 'Latency':
        return `~${attributeValue} ms`;
      case 'Media file format':
        return `.${attributeValue}`;
      case 'Media Resolution':
        return `${attributeValue} px`;

      default:
        return capitalize(attributeValue);
    }
  };

  return (
    <S.ConditionItem>
      <span>{attribute.name}</span>
      <InfoIcon info={attribute.description} />

      <S.GradeContainer>
        {formatAttribute(attribute.name, attribute.value)}
        <Grade size="sm">{attribute.grade}</Grade>
      </S.GradeContainer>
    </S.ConditionItem>
  );
};

export default ConditionItem;
