import MuiModal from '@mui/material/Modal';
import styled, { css } from 'styled-components/macro';

import minusIcon from 'assets/images/icons/minus-button.svg';
import plusIcon from 'assets/images/icons/plus-button.svg';
import { mediaQueries } from 'styles/media';

interface ImgContainer {
  isPageMode?: boolean; //TODO: workaround - refactor
}

export const ImgContainer = styled.div<ImgContainer>`
  position: relative;
  margin: auto;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 64vh;
    aspect-ratio: 1;
    object-fit: cover;
    z-index: 5;

    ${mediaQueries.tablet} {
      height: auto;
      width: 80vw;
    }

    ${mediaQueries.xs} {
      width: 100%;
      height: auto;
    }
  }

  ${({ isPageMode }) => {
    if (isPageMode) {
      return css`
        width: 80%;
        img {
          height: auto;
          max-height: 60vh;
          width: 100%;
        }

        ${mediaQueries.xs} {
          width: 100%;
          height: auto;
        }
      `;
    }
  }}
`;

export const Modal = styled(MuiModal)`
  .MuiBackdrop-root {
    background-color: white !important;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  figure {
    box-shadow: #6566f359 0px 0px 10px 0px;
  }

  img {
    cursor: url('${minusIcon}'), auto;
  }

  .iiz {
    cursor: url('${plusIcon}'), auto;
  }

  .iiz__img {
    max-width: none;
    height: 90vh;
    cursor: pointer;

    @media (max-width: 600px) {
      height: auto;
      width: 90vw;
    }

    ${mediaQueries.tablet} {
      height: auto;
      width: 90vw;
    }
  }
`;

export const OpenButton = styled.img`
  position: absolute;
  width: 56px !important;
  height: 56px !important;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 10 !important;
`;

export const CloseButton = styled.img`
  position: absolute;
  right: 2%;
  top: 2%;
  z-index: 2000;
  cursor: pointer;
`;
