import LevelBar from './components/LevelBar';
import * as S from './styles';

interface TotalCo2 {
  totalCO2: number;
  totalSimulatedCO2: number;
  currentChainName: string;
}

const TotalCo2 = ({
  totalCO2,
  totalSimulatedCO2,
  currentChainName,
}: TotalCo2) => {
  return (
    <S.Continer>
      <S.Title>Total CO2</S.Title>

      <S.Item isFirst>
        <S.ItemTitle isFirst>
          {currentChainName === 'ethereum' && 'This ETH Address'}
          {currentChainName === 'hedera' && 'This Hedera Address'}
          {currentChainName === 'polygon' && 'This Polygon Address'}
        </S.ItemTitle>
        <LevelBar value={totalCO2} />
      </S.Item>

      <S.Item isFirst>
        <S.ItemTitle isFirst>
          {currentChainName === 'ethereum' && 'On Hedera Address'}
          {currentChainName === 'hedera' && 'On Ethereum Address'}
          {currentChainName === 'polygon' && 'On Hedera Address'}
        </S.ItemTitle>
        <LevelBar value={totalSimulatedCO2} />
      </S.Item>

      <S.Item>
        <S.ItemTitle>Avg. Car Dive - London &gt; Madrid</S.ItemTitle>
        <LevelBar value={27.36} />
      </S.Item>

      <S.Item>
        <S.ItemTitle>Flight - JFK &gt; LHR</S.ItemTitle>
        <LevelBar value={1200} />
      </S.Item>

      <S.Item>
        <S.ItemTitle>Avg. 1 Week Cruise / passenger</S.ItemTitle>
        <LevelBar value={1680} />
      </S.Item>

      <S.Item>
        <S.ItemTitle>Avg. US Household / Year</S.ItemTitle>
        <LevelBar value={7500} />
      </S.Item>

      <S.Scale>
        <S.ScaleItem>
          <S.ScaleValue>0</S.ScaleValue>
        </S.ScaleItem>
        <S.ScaleItem>
          <S.ScaleValue>3000</S.ScaleValue>
        </S.ScaleItem>
        <S.ScaleItem>
          <S.ScaleValue>6000</S.ScaleValue>
        </S.ScaleItem>
        <S.ScaleItem>
          <S.ScaleValue>9000</S.ScaleValue>
        </S.ScaleItem>
      </S.Scale>
    </S.Continer>
  );
};

export default TotalCo2;
