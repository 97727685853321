import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import ScrollToTop from 'components/ScrollToTop';
import Layout from 'Layout';
import GlobalStyle from 'styles/globalStyles';

import Router from './router';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <GlobalStyle />
      <Layout>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
