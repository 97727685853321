import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import InfoIcon from 'components/InfoIcon';

import * as S from './styles';

interface IMuiAccordion {
  id: number | string;
  title: string | JSX.Element;
  rightTitle?: string | JSX.Element;
  info?: string;
  expanded?: boolean;
  children: React.ReactNode;
  [rest: string]: any;
}

const MuiAccordion = ({
  id,
  title,
  rightTitle,
  info,
  children,
  isExpanded = false,
  ...rest
}: IMuiAccordion) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <S.Accordion
      {...rest}
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded((prev: boolean) => !prev)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <S.Title>
          {title} {info && <InfoIcon info={info} />}
          {rightTitle && <S.RightTitle>{rightTitle}</S.RightTitle>}
        </S.Title>
      </AccordionSummary>
      <AccordionDetails>
        <S.Content>{children}</S.Content>
      </AccordionDetails>
    </S.Accordion>
  );
};

export default MuiAccordion;
