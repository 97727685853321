import { useNavigate } from 'react-router-dom';

import heroImg from 'assets/images/hero-img.png';
import PillButton from 'components/Buttons/PillButton';

import * as S from './styles';

const Hero = () => {
  const navigate = useNavigate();

  const scrollToGallery = () => {
    navigate('/#gallery-landing');
  };

  return (
    <S.Container>
      <S.Content id="content">
        <S.LeftCol id="left-col">
          <S.TopText id="top-text">
            <S.Title> Introducing Condition Reports for NFTS</S.Title>

            <S.BigTitle>
              Not all NFTs are <br />
              <span style={{ color: '#9E7CFF' }}> created equal</span>
            </S.BigTitle>

            {/* <S.Subtitle>
              Not all NFTs are created equal lorem ipsum dolor sit amet,
              consectetur <br /> adipiscing elit. Maecenas a dolor maximus,
              tincidunt elit vitae.
            </S.Subtitle> */}

            <PillButton
              label="SEE HOW IT WORKS!"
              variant="outlined-transparent"
              width="246px"
              icon="smallArrowDown"
              onClick={scrollToGallery}
            />
          </S.TopText>
        </S.LeftCol>

        <S.RightCol>
          <S.Img src={heroImg} alt="hero-img" />
        </S.RightCol>
      </S.Content>

      <S.BottomText id="gallery-landing">
        <p>Evidence of Code Unseen</p>

        <span>Select an NFT to generate a Condition Report</span>
      </S.BottomText>
    </S.Container>
  );
};

export default Hero;
