import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as HederaIcon } from 'assets/images/icons/hedera.svg';
import { ReactComponent as SmallDownArrow } from 'assets/images/icons/small-down-arrow.svg';
import { ReactComponent as Isotype } from 'assets/images/logos/grade-iso-small.svg';

import * as S from './styles';

const icons = {
  arrow: ArrowCircleRightRoundedIcon,
  arrowFordward: ArrowForwardIcon,
  smallArrowDown: SmallDownArrow,
  wallet: AccountBalanceWalletOutlinedIcon,
  dashboard: DashboardRoundedIcon,
  download: DownloadRoundedIcon,
  add: AddRoundedIcon,
  remove: RemoveRoundedIcon,
  copy: ContentCopyRoundedIcon,
  launch: LaunchRoundedIcon,
  isotype: Isotype,
  replay: ReplayIcon,
  emailOutlined: EmailOutlinedIcon,
  arrowUpward: ArrowUpwardIcon,
  hedera: HederaIcon,
};

export interface PillButton {
  label: string;
  variant?:
    | 'primary'
    | 'secundary'
    | 'outlined'
    | 'outlined-black'
    | 'white'
    | 'disabled'
    | 'outlined-transparent';
  icon?:
    | 'arrow'
    | 'arrowFordward'
    | 'wallet'
    | 'dashboard'
    | 'download'
    | 'add'
    | 'remove'
    | 'copy'
    | 'isotype'
    | 'replay'
    | 'launch'
    | 'hedera'
    | 'smallArrowDown'
    | 'arrowUpward';

  iconLeftPos?: boolean;
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  small?: boolean;
  [rest: string]: any;
}

const PillButton = ({
  label,
  variant,
  icon,
  iconLeftPos,
  disabled,
  loading,
  width,
  small,
  ...rest
}: PillButton) => {
  const SelectedIcon = icon !== undefined ? icons[icon] : () => null;
  return (
    <S.StyledButton
      {...rest}
      small={small}
      variant={variant}
      icon={icon}
      iconLeftPos={iconLeftPos}
      width={width}
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={label}
      type="submit"
    >
      {loading ? (
        <CircularProgress
          color="inherit"
          style={{ width: '30px', height: '30px' }}
        />
      ) : (
        <>
          <S.Label>{label}</S.Label>
          <SelectedIcon />
        </>
      )}
    </S.StyledButton>
  );
};

export default PillButton;
