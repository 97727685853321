import BoltIcon from '@mui/icons-material/Bolt';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BookCkeckIcon } from 'assets/images/icons/book-check.svg';
import { ReactComponent as CodeIcon } from 'assets/images/icons/code.svg';
import { ReactComponent as EtherRoundedIcon } from 'assets/images/icons/ether-rounded.svg';
import { ReactComponent as HederaRoundedIcon } from 'assets/images/icons/hedera-rounded.svg';
import { ReactComponent as PolygonIcon } from 'assets/images/icons/polygon.svg';
import PillAddressButton from 'components/Buttons/PillAddressButton';
import Grade from 'components/Grade';
//import InfoIcon from 'components/InfoIcon';
import MuiAccordion from 'components/MuiAccordion';
import Show from 'components/Show';
import { toggleSubscriptionModal } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';
import { formatDate } from 'utils';

import * as S from './styles';

const ReportDetail = ({ data, dataEnergy }) => {
  const { data: energyData, isLoading: energyLoading } = dataEnergy;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tooggleSubscription = () => {
    dispatch(toggleSubscriptionModal());
  };
  const detailIcons = {
    code: <CodeIcon />,
    media: <PermMediaIcon />,
    'energy consumption': <BoltIcon />,
    provenance: <BookCkeckIcon />,
  };

  const navigateToFullReport = (e: React.MouseEvent<HTMLElement>) => {
    const element = e.currentTarget;
    const category = element.dataset.id || '';
    navigate(`?type=full&category=${category.toLowerCase()}`);
  };

  const criteriaCategories = data?.condition_report?.criteria_categories;
  const newCriteriaCategories = [...criteriaCategories];
  const hasNotEnergy = newCriteriaCategories.every(
    (el) => el.name !== 'Energy Consumption'
  );
  const tokenType = data.nft.token_type;

  // Add missing categories
  if (hasNotEnergy && tokenType !== 'ERC-1155') {
    newCriteriaCategories.push({
      name: 'Energy Consumption',
      condition_letter: '',
    });
  }

  newCriteriaCategories.push({
    name: 'provenance',
    condition_letter: '',
  });

  const EnergyConsumptionVal =
    energyData?.energy_consumption_report?.value || 0;
  const chainId = data.nft?.chain_id;
  const chainName =
    chainId === 1 ? 'ethereum' : chainId === 2 ? 'hedera' : 'polygon';

  return (
    <S.Container>
      <S.Featured>
        <S.FeaturedTitle>NFT Details</S.FeaturedTitle>

        {data?.nft?.description && (
          <div>
            <S.DetailTitle>Description</S.DetailTitle>
            {data.nft.description}
          </div>
        )}

        <S.GridDetails>
          <S.Detail>
            <S.DetailTitle>Chain</S.DetailTitle>
            <S.ChainDetail>
              {chainName === 'ethereum' && (
                <>
                  <EtherRoundedIcon />
                  Ethereum Mainnet
                </>
              )}

              {chainName === 'hedera' && (
                <>
                  <HederaRoundedIcon />
                  Hedera Mainnet
                </>
              )}

              {chainName === 'polygon' && (
                <>
                  <PolygonIcon />
                  Polygon Mainnet
                </>
              )}
            </S.ChainDetail>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle style={{ display: 'flex', gap: '10px' }}>
              {chainName === 'ethereum' || chainName === 'polygon'
                ? 'Smart Contract Address'
                : 'Token ID'}
            </S.DetailTitle>
            <strong>
              <PillAddressButton
                label={data.nft?.contract_address}
                icon="copy"
                iconPositionRight
              />
            </strong>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>
              {chainName === 'ethereum' || chainName === 'polygon'
                ? tokenType === 'ERC-1155'
                  ? 'Batch ID '
                  : 'Token ID '
                : 'Serial #'}
            </S.DetailTitle>
            <strong>
              <PillAddressButton
                label={`#${data.nft?.token_id}`}
                icon="copy"
                iconPositionRight
              />
            </strong>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>
              {chainName === 'ethereum' || chainName === 'polygon'
                ? 'Contract Standard'
                : 'Token Standard'}
            </S.DetailTitle>
            <strong>{data.nft?.token_type}</strong>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>Created by</S.DetailTitle>
            <S.DetailContent>
              {data.nft?.ens_created_by}
              <PillAddressButton
                label={data.nft?.created_by}
                icon={chainName}
                showCopyIcon
              />
            </S.DetailContent>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>Mint Timestamp</S.DetailTitle>
            <strong>{formatDate(data.nft?.mint_timestamp)}</strong>
          </S.Detail>

          <Show when={energyData}>
            <S.Detail>
              <S.DetailTitle>Owned by</S.DetailTitle>
              <strong
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {data.nft?.owner[0]?.ens_owner_name}
                <PillAddressButton
                  label={data.nft?.owner[0]?.owner_name}
                  icon={chainName}
                  showCopyIcon
                />
              </strong>
            </S.Detail>
          </Show>
        </S.GridDetails>
      </S.Featured>

      {/* Criteria Summary */}
      <MuiAccordion id="criteria-summary" title="Criteria Summary">
        <S.SummaryContainer>
          {newCriteriaCategories &&
            newCriteriaCategories.map((el, key: number) => {
              return (
                <S.SummaryItem
                  key={key}
                  onClick={navigateToFullReport}
                  data-id={el.name.toLowerCase().replaceAll(' ', '-')}
                >
                  {detailIcons[el.name.toLowerCase()]}
                  <span>{el.name}</span>
                  <Grade size="md">{el.condition_letter}</Grade>
                </S.SummaryItem>
              );
            })}
        </S.SummaryContainer>
      </MuiAccordion>

      {/* Energy Consumption Summary */}
      <MuiAccordion id="energy-consumption" title="Energy Consumption Summary">
        <Show when={energyLoading || energyData}>
          <>
            {energyLoading ? (
              <h4>Loading data...</h4>
            ) : (
              <h4>~ {EnergyConsumptionVal?.toLocaleString()} kWh</h4>
            )}

            <p>
              Reflects cost of minting and all subsequent activities including
              transfers and sales.
            </p>
          </>
        </Show>

        <Show when={!energyData}>
          <>
            <h4 style={{ color: 'var(--grey)' }}>Unavailable</h4>
            <p>
              The energy consumption analysis is not currently supported for
              ERC-1155 standards.We are actively working to develop and
              integrate it into the platform.
            </p>
            <p>
              Please{' '}
              <S.HightLighted onClick={tooggleSubscription} role="button">
                stay in touch
              </S.HightLighted>{' '}
              for updates.
            </p>
          </>
        </Show>
      </MuiAccordion>

      {/* Provenance Summary */}
      <MuiAccordion id="provenance-summary" title="Provenance Summary">
        <p style={{ margin: 0 }}>
          We have exciting ideas about how this feature and are actively working
          to develop and integrate it into the platform.
        </p>
        <p>
          Please{' '}
          <S.HightLighted onClick={tooggleSubscription} role="button">
            stay in touch
          </S.HightLighted>{' '}
          for updates.
        </p>
      </MuiAccordion>
    </S.Container>
  );
};

export default ReportDetail;
