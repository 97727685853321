import { ReactComponent as EtherRoundedIcon } from 'assets/images/icons/ether-rounded.svg';
import { ReactComponent as HederaRoundedIcon } from 'assets/images/icons/hedera-rounded.svg';
import { ReactComponent as PolygonButtonDotIcon } from 'assets/images/icons/polygon-button-dot.svg';
import noImage from 'assets/images/no-image.jpg';
import spinnerGif from 'assets/images/spinner.gif';
import { formatText } from 'utils';

import * as S from './styles';

const ImageGalleryItem = ({ nftData, to }) => {
  const onError = (e) => {
    e.target.src = noImage;
  };

  const chainId = nftData.chain_id;

  return (
    <S.Continer to={to}>
      <S.Spinner>
        <img src={spinnerGif} alt="Spinner" style={{ width: '50px' }} />
      </S.Spinner>

      <S.Content>
        {nftData.mediaType === 'video' ? (
          <S.Video
            src={`${nftData.image_url}#t=0.5`}
            autoPlay
            muted
            loop
            webkit-playsinline
            playsInline
            preload="metadata"
            controls={false}
          >
            <p>Your browser doesn&apos;t support HTML5 video.</p>
          </S.Video>
        ) : (
          <img
            src={nftData.image_url}
            onError={onError}
            alt={`gallery-item ${nftData?.name}`}
          />
        )}

        <S.TextContainer>
          {chainId === 1 && <EtherRoundedIcon />}
          {chainId === 2 && <HederaRoundedIcon />}
          {chainId === 3 && <PolygonButtonDotIcon />}

          <S.Text>
            <S.Name>{formatText(nftData?.created_by || '')}</S.Name>
            <S.Description>
              {nftData?.name || `#${nftData?.token_id}`}
            </S.Description>
          </S.Text>
          <S.ArrowIcon />
        </S.TextContainer>
      </S.Content>
    </S.Continer>
  );
};

export default ImageGalleryItem;
