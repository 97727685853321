import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';

const ContentPreloader = () => {
  return (
    <S.Continer>
      <CircularProgress color="inherit" />
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ContentPreloader;
