import { ReactComponent as MinusIcon } from 'assets/images/icons/ic_round-minus.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/ic_round-plus.svg';

import * as S from './styles';

interface Props {
  children: string | undefined;
  size?: 'sm' | 'md' | 'lg';
}

const Grade = ({ children, size }: Props) => {
  const formatLetter = (children) => {
    if (children) {
      const splittedLetter = children.split('');
      const letter = splittedLetter[0];
      const simbol = splittedLetter[1];
      const isNegative = simbol === '-';
      const colors = {
        a: '#28CD87',
        b: '#FFB31F',
        c: '#FF8E4F',
        d: '#FF4F4F',
        F: '#FF4F4F',
      };
      const currentBgColor = colors[letter.toLowerCase()];

      return (
        <S.Continer size={size} bgColor={currentBgColor}>
          {letter}
          {simbol && (
            <S.Badge size={size} badgeToBottom={isNegative}>
              {isNegative ? <MinusIcon /> : <PlusIcon />}
            </S.Badge>
          )}
        </S.Continer>
      );
    } else {
      return (
        <S.Continer size={size} bgColor={'#BBBBBB'}>
          <S.Dots size={size}>-</S.Dots>
        </S.Continer>
      );
    }
  };

  const Content = () => formatLetter(children);

  return <Content />;
};

export default Grade;
