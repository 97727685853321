import { useState } from 'react';

import Drawer from '@mui/material/Drawer';
import styled from 'styled-components/macro';

import CookiesBanner from 'components/CookiesBanner';
//import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import Modal from 'components/Modals/Modal';
import NavBar from 'components/NavBar';
import SearchNftBox from 'components/SearchNftBox';
import SubscribeForm from 'components/Subscribe/SubscribeForm';
import { toggleDrawer, toggleSubscriptionModal } from 'store/globalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mediaQueries } from 'styles/media';

interface IProps {
  children: JSX.Element;
}

const Layout = ({ children }: IProps): JSX.Element => {
  const [cookiesBannerVisible, setCookiesBannerVisible] = useState(true);
  const dispatch = useAppDispatch();
  const searchDrawerVisibility = useAppSelector(
    (state) => state.global.ui.searchDrawerVisibility
  );
  const subscriptionVisibility = useAppSelector(
    (state) => state.global.ui.subscriptionModalVisibility
  );
  const tooggleSearchNft = () => {
    dispatch(toggleDrawer());
  };
  const tooggleSubscription = () => {
    dispatch(toggleSubscriptionModal());
  };

  return (
    <>
      <Modal isOpen={subscriptionVisibility} close={tooggleSubscription}>
        <SubscribeForm close={tooggleSubscription} />
      </Modal>
      <S.Drawer anchor="right" open={searchDrawerVisibility}>
        <SearchNftBox tooggleSearchNft={tooggleSearchNft} />
      </S.Drawer>
      {cookiesBannerVisible && (
        <CookiesBanner setCookiesBannerVisible={setCookiesBannerVisible} />
      )}

      <NavBar tooggleSearchNft={tooggleSearchNft} />
      {children}
      <Footer tooggleSearchNft={tooggleSearchNft} />
    </>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Drawer = styled(Drawer)`
  top: 88px !important;
  .MuiPaper-root {
    box-sizing: border-box;
    width: 580px;
    background-color: #ffffff;
    color: #000000;
    box-shadow: none;
    ${mediaQueries.sm} {
      width: 100vw;
    }
  }
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.8);
  }
`;

export default Layout;
