import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Container = styled.div`
  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  ${mediaQueries.md} {
    padding: 20px;
  }
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: 5px;

  ${mediaQueries.md} {
    flex-direction: column;
    text-align: center;
  }
`;
