import Icon from '@mui/icons-material/Info';

import ToolTip from 'components/ToolTip';

const InfoIcon = ({ info }) => {
  return (
    <ToolTip title={info}>
      <Icon style={{ color: 'var(--darkGrey)' }} />
    </ToolTip>
  );
};

export default InfoIcon;
