import { useEffect } from 'react';

import { useLocation } from 'react-router';

import ContentPreloader from 'components/ContentPreloader';
import { useGetGalleryQuery } from 'services';
import { scrollToHtmlEl } from 'utils';

import Hero from './components/Hero';
import ImageGallery from './components/ImageGallery';

const Landing = () => {
  const location = useLocation();
  const hash = location.hash;
  useEffect(() => {
    const navBarHeight = 88; //Pixels
    const anchorElement = document.getElementById(hash.substring(1));
    if (hash === '#gallery-landing' && anchorElement) {
      scrollToHtmlEl(hash, navBarHeight);
      window.location.hash = '#nft-gallery';
    }
  }, [hash]);
  const { data, error, isLoading } = useGetGalleryQuery('');

  return (
    <main>
      <Hero />
      <section>
        {!isLoading && !error ? (
          <ImageGallery data={data} />
        ) : (
          <ContentPreloader />
        )}
      </section>
    </main>
  );
};

export default Landing;
