import styled from 'styled-components/macro';

import { ReactComponent as StyledLinkIcon } from 'assets/images/icons/link.svg';
import { mediaQueries } from 'styles/media';

export const Continer = styled.div`
  padding-top: 24px;
  background-color: var(--pale);
  ${mediaQueries.md} {
    padding-top: 0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  gap: 34px;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  color: var(--dark);
  strong {
    color: black;
  }
  ${mediaQueries.md} {
    border-radius: 0px;
    border-left: none;
    border-right: none;
    flex-direction: column-reverse;
  }
`;

export const LeftDetail = styled.div`
  width: 50%;
  word-break: break-word;
  ${mediaQueries.md} {
    width: 100%;
  }
`;

export const RightDetail = styled.div`
  width: 50%;
  ${mediaQueries.md} {
    width: 100%;
  }
`;

export const DetailTitle = styled.p`
  font-size: 12px;
`;

export const DetailContent = styled.strong`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mediaQueries.xl} {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const ChainDetail = styled.strong`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GridDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  strong {
    font-weight: 600;
  }
  ${mediaQueries.md} {
    grid-template-columns: 1fr;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

export const LinkIcon = styled(StyledLinkIcon)`
  position: relative;
  top: 4px;
  left: 2px;
  margin-right: 4px;
  cursor: pointer;
`;

export const SubCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  font-weight: 600;
`;

export const ItemEmphasis = styled.span`
  position: absolute;
  right: 10px;
  color: var(--darkGrey);
`;

export const GradeLevel = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
  margin-bottom: 24px;
  background-color: white;
  padding: 24px;
  width: 100%;
  justify-content: space-between;
`;