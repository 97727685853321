import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import { mediaQueries } from 'styles/media';

interface IParams {
  close: () => void;
  email: string;
}

const SubscribeSuccess = ({ close, email }: IParams) => {
  return (
    <S.Continer>
      <h2>Thanks for Subscribing!</h2>
      <p>
        You successfully subscribed to our newsletter, we will be sending you
        more information to: <S.Email>{email}</S.Email>
      </p>

      <S.ButtonWrapper>
        <PillButton
          type="submit"
          label="Back to NFT Grade"
          variant="primary"
          icon="arrowFordward"
          onClick={close}
        />
      </S.ButtonWrapper>
    </S.Continer>
  );
};

const S: any = {};

S.Continer = styled.div`
  height: 100%;
  position: relative;
`;

S.Email = styled.div`
  text-decoration: underline;
  font-weight: 500;
`;

S.ButtonWrapper = styled.div`
  margin-top: 48px;

  ${mediaQueries.md} {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

export default SubscribeSuccess;
