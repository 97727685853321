import { createGlobalStyle } from 'styled-components';

import sliderDotsBgHorizontal from 'assets/images/slider-dots-bg-horizontal.png';
import sliderDotsBgVertical from 'assets/images/slider-dots-bg-vertical.png';
import { mediaQueries } from 'styles/media';

const GlobalStyle = createGlobalStyle`
 :root {
    // Colors
    --secundary: #6566F3;
    --accent: #6566F3;
    --accentDark: #222276;
    --success: #3AE29B;
    --alert: #F9B146;
    --error: #F1406B;

    --dark: #202020;
    --darkGrey: #757575;
    --grey: #BBBBBB;
    --lightGrey: #DDDDDD;
    --pale: #F2F2F2;

    --grey80: rgba(0, 0, 0, 0.8);
    --grey60: rgba(0, 0, 0, 0.6);
    --grey40: rgba(0, 0, 0, 0.4);
    --grey20: rgba(0, 0, 0, 0.2);
    --grey10: rgba(0, 0, 0, 0.1);
    --grey5: rgba(0, 0, 0, 0.05);
 
    // Paddings
    --desktopViewPadding: 0 64px 0 64px;
    --mobileViewPadding: 0 24px 0 24px;
  }

  div, img {
    outline: none !important;
  }

  html {
    font-size: 16px;
  }
  
  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background: white;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  h1 { 
    font-size: 3rem; 
    font-weight: 600;
    ${mediaQueries.md} {
      font-size: 2.5rem;
    }
  }

  h2 { 
    font-size: 2.5rem; 
    font-weight: 600;
    ${mediaQueries.lg} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 600;
    ${mediaQueries.lg} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    ${mediaQueries.lg} {
      font-size: 1.25rem;
    }
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    ${mediaQueries.lg} {
      font-size: 1.12rem;
    }
  }

  small {
    font-size: 0.75rem;
  }

  a {
    text-decoration: none;
    color: black;
  }

  /* ==========================================================================
  Slider
  ========================================================================== */

  .slick-slider {
    display: flex;

    ${mediaQueries.md} {
      flex-direction: column-reverse;
    }

    .slick-dots li button:before {
      font-size: 18px;
    }

    .slick-dots {
      position: absolute;
      display: flex;
      width: fit-content;
      padding: 16px 10px 16px 42px;
      flex-direction: column;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;
      background-color: white;
      background-image: url(${sliderDotsBgVertical});
      background-repeat: no-repeat;
     
      background-size: 58%;
      border-radius: 0 8px 8px 0;
      background-position: center left;

      li {
        margin: 6px 0 6px 0;

        ${mediaQueries.md} {
          margin: 0 5px;
        }
      }

      ${mediaQueries.md} {
        position: relative;
        flex-direction: row;
        transform: none;
        top: 0;
        margin: auto;
        padding: 52px 12px 16px 12px;
        background-image: url(${sliderDotsBgHorizontal});
        background-size: contain;
        background-position: 0 6px;
        transform: unset;
      }
    }
  }
`;

export default GlobalStyle;
