import { useState, useEffect } from 'react';

import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import TextField from 'components/Form/TextField';
import { useGetNftByAddressAndTokenQuery } from 'services';
import { toggleSubscriptionModal } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';

interface IFormValues {
  contractAddress: number | string;
  tokenId: number | string;
}

const HederaForm = ({ tooggleSearchNft }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<IFormValues>({
    contractAddress: '',
    tokenId: '',
  });
  const [reqError, setReqError] = useState('');
  const [skip, setSkip] = useState(true);
  const { data, error, refetch, isFetching, isSuccess } =
    useGetNftByAddressAndTokenQuery(
      {
        address: formValues?.contractAddress,
        token: formValues?.tokenId,
        chainId: 2,
      },
      {
        skip,
      }
    );
  const dispatch = useAppDispatch();
  const tooggleSubscription = () => {
    dispatch(toggleSubscriptionModal());
  };

  function validateNumber(val) {
    let error;
    if (!val) {
      error = 'Required';
    } else if (!/^[0-9]+$/i.test(val)) {
      error = 'Only numbers are allowed';
    }
    return error;
  }

  const onSubmit = (values: IFormValues) => {
    setFormValues(values);
    setSkip(false);
    if (!skip) {
      refetch();
    }
  };

  interface IError {
    error: string;
    message: string;
    statusCode: number;
  }

  useEffect(() => {
    if (error) {
      if ('data' in error) {
        const infoError = error.data as IError;
        setReqError(infoError.message || 'Oops! Something went wrong.');
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      const isEmptyResult = Object.keys(data).length === 0;
      if (isEmptyResult) {
        setReqError('No results found.');
      } else {
        tooggleSearchNft();
        navigate(
          `/nft/preview?address=${formValues.contractAddress}&token-id=${formValues.tokenId}&chain-id=2`
        );
      }
    }
  }, [isFetching, isSuccess]);

  return (
    <Formik
      initialValues={{ contractAddress: '', tokenId: '' }}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, isValid }) => (
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Field
              as={TextField}
              type="text"
              name="contractAddress"
              value={values.contractAddress}
              placeholder="eg. 0.0.12345"
              label={
                <>
                  Token ID
                  <span style={{ color: 'var(--error)' }}>*</span>
                </>
              }
              labelCentered
              validate={(val) => (!val ? 'Required' : '')}
              errorMessage={errors.contractAddress}
              touched={touched.contractAddress}
              width="100%"
              margin="0 0 25px 0"
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
                textIndent: 0,
              }}
            />
            <Field
              as={TextField}
              type="name"
              name="tokenId"
              value={values.tokenId}
              placeholder="eg. 999"
              label={
                <>
                  Serial #<span style={{ color: 'var(--error)' }}>*</span>
                </>
              }
              labelCentered
              validate={validateNumber}
              errorMessage={errors.tokenId}
              touched={touched.tokenId}
              width="100%"
              margin="0 0 25px 0"
              style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
                textIndent: 0,
              }}
            />

            {reqError && (
              <S.RequestError>
                <span style={{ color: 'var(--error)' }}>{reqError}</span> <br />
                Please{' '}
                <span
                  onClick={tooggleSubscription}
                  role="button"
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  subscribe
                </span>{' '}
                for updates
              </S.RequestError>
            )}
          </div>

          <PillButton
            loading={isFetching}
            type="submit"
            label="search"
            variant="secundary"
            width="100%"
            disabled={
              Object.values(values).some(
                (formElementVal) => formElementVal === ''
              ) || !isValid
            }
          />
        </Form>
      )}
    </Formik>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.RequestError = styled.div`
  text-align: center;
  color: var(--lightGrey);
`;

export default HederaForm;
