import PillButton from 'components/Buttons/PillButton';
import Modal from 'components/Modals/Modal';

import * as S from './styles';

const DownloadPdfModal = ({ isOpen, close, hederaLink }) => {
  const handleHedera = () => {
    window.open(hederaLink, '_blank');
  };
  const isLoading = hederaLink ? false : true;
  return (
    <Modal isOpen={isOpen} close={close}>
      <S.Container>
        <div>
          <h2>Downloading PDF...</h2>
          <p>
            This PDF version of the NFT Condition Report has also been
            successfully <br /> uploaded and linked to Hedera.
          </p>
        </div>

        <S.ButtonsContainer>
          {/* <PillButton
            label="See on Hedera"
            variant="outlined-black"
            icon="hedera"
            onClick={handleHedera}
            loading={isLoading}
            disabled={isLoading}
          /> */}
          <PillButton label="Continue" icon="arrowFordward" onClick={close} />
        </S.ButtonsContainer>
      </S.Container>
    </Modal>
  );
};

export default DownloadPdfModal;
