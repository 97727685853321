import { useSearchParams, useNavigate } from 'react-router-dom';

import ContentPreloader from 'components/ContentPreloader';
import PreviewImage from 'components/PreviewImage';
import { useGetNftByUrlQuery, useGetNftByAddressAndTokenQuery } from 'services';

import PreviewDetails from '../components/PreviewDetails';
import * as S from './styles';

const SearchPreview = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  //Url posible params
  const urlParam = search.get('url');
  const addressParam = search.get('address');
  const tokenIdParam = search.get('token-id');
  const chainId = search.get('chain-id');

  //Subscribe to the cached data
  const { data, error, isLoading } = urlParam
    ? useGetNftByUrlQuery(
        {
          url: urlParam,
          chainId: chainId,
        },
        {
          skip: !urlParam,
        }
      )
    : useGetNftByAddressAndTokenQuery(
        {
          address: addressParam,
          token: tokenIdParam,
          chainId: chainId,
        },
        {
          skip: !addressParam && !tokenIdParam,
        }
      );

  const generateReport = () => {
    if (!error) {
      const { contract_address, token_id } = data.nft;
      navigate(`/nft/report/${contract_address}/${token_id}/${chainId}`);
    }
  };

  if (isLoading) return <ContentPreloader />;

  return (
    <S.Continer>
      {!error ? (
        <>
          <S.MediaContainer>
            {data.nft?.mediaType === 'video' ? (
              <S.Video
                key={data.nft?._id}
                src={data.nft?.image_url}
                autoPlay
                muted
                controls={false}
                loop
              >
                <p>
                  Your browser doesn&apos;t support HTML5 video. Here is a{' '}
                  <a
                    href={data.nft?.image_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    link to the video
                  </a>{' '}
                  instead.
                </p>
              </S.Video>
            ) : (
              <PreviewImage src={data?.nft?.image_url} />
            )}
          </S.MediaContainer>

          <PreviewDetails
            data={data?.nft}
            generateReport={generateReport}
            searchButtonVisible
          />
        </>
      ) : (
        <h2 style={{ padding: '60px' }}>Oops! Something went wrong.</h2>
      )}
    </S.Continer>
  );
};

export default SearchPreview;
