export interface Devices {
  xss: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  tablet: string;
  tabletLandscape: string;
}

export const deviceSizes: Devices = {
  xss: '390px',
  xs: '428px',
  sm: '600px',
  md: '960px',
  lg: '1440px',
  xl: '1920px',
  tablet: '820px',
  tabletLandscape: '1180px',
};

export const mediaQueries: Devices = {
  xss: `@media (max-width: ${deviceSizes.xss})`,
  xs: `@media (max-width: ${deviceSizes.xs})`,
  sm: `@media (max-width: ${deviceSizes.sm})`,
  md: `@media (max-width: ${deviceSizes.md})`,
  lg: `@media (max-width: ${deviceSizes.lg})`,
  xl: `@media (max-width: ${deviceSizes.xl})`,
  tablet: `@media (max-width: ${deviceSizes.tablet}) and (orientation: portrait)`,
  tabletLandscape: `@media (max-width: ${deviceSizes.tabletLandscape}) and (orientation: landscape)`,
};
