import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const Featured = styled.div`
  display: grid;
  gap: 30px;
  background-color: white;
  border: 1px solid var(--lightGrey);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
  word-break: break-all;
  p {
    margin: 0 0 10px 0;
  }
  ${mediaQueries.md} {
    border-radius: 0;
  }
`;

export const FeaturedTitle = styled.h5`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GridDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  strong {
    font-weight: 600;
  }
  ${mediaQueries.md} {
    grid-template-columns: 1fr;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

export const DetailTitle = styled.p`
  font-size: 12px;
`;

export const DetailContent = styled.strong`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mediaQueries.xl} {
    flex-direction: column;
    align-items: baseline;
  }
`;

export const ChainDetail = styled.strong`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const SummaryItem = styled.div`
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  grid-gap: 10px;
  justify-items: end;
  align-items: center;
  position: relative;
  padding: 0 16px 0 16px;
  cursor: pointer;
  background-color: #f4f4f4;
  border: 1px solid #dddddd;
  height: 56px;
  border-radius: 8px;
  text-transform: capitalize;

  &:hover {
    background-color: #000000;
    color: white;
    fill: white;
  }
`;

export const GradeValueIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  width: 24px;
  height: 24px;
  border-radius: 8px;
  color: white;
  position: absolute;
  right: 16px;
`;

export const HightLighted = styled.span`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;
