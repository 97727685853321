import { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateRightRoundedIcon from '@mui/icons-material/RotateRightRounded';
import styled from 'styled-components/macro';

import { ReactComponent as BookCkeckIcon } from 'assets/images/icons/book-check.svg';
import { mediaQueries } from 'styles/media';

const LineItem = ({ itemName, itemOrder, currentStep, totalItems }) => {
  const lastItem = totalItems;
  return (
    <>
      <S.LineItem>
        {itemOrder === currentStep ? (
          <S.Spinner />
        ) : itemOrder === lastItem ? (
          <S.BookCkeckIcon disabled={currentStep < itemOrder} />
        ) : (
          <S.CheckCircleIcon disabled={currentStep < itemOrder} />
        )}
        <S.ItemDescription disabled={currentStep < itemOrder}>
          {itemName}
        </S.ItemDescription>
      </S.LineItem>
      {itemOrder < lastItem ? <S.LineDivision /> : null}
    </>
  );
};

const ReportPreloader = () => {
  const lineItems = ['Code', 'Media', 'Energy', 'Compliance'];
  const totalItems = lineItems.length;
  const stepTime = 1500;
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading) setCurrentStep((prev) => ++prev);
    }, stepTime);

    if (!isLoading) clearInterval(interval);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (currentStep === totalItems + 1) setIsLoading(false);
  }, [currentStep]);

  return (
    <S.Continer>
      <h3 style={{ textAlign: 'center' }}>Generating Condition Report</h3>
      <p>Analyzing...</p>

      <S.LineContainer>
        {lineItems.map((name, key) => (
          <LineItem
            key={key}
            itemName={name}
            itemOrder={++key}
            currentStep={currentStep}
            totalItems={totalItems}
          />
        ))}
      </S.LineContainer>
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: black;
  color: white;
`;

S.Spinner = styled(RotateRightRoundedIcon)`
  color: var(--alert);
  animation: spin 2s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

S.LineContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  ${mediaQueries.sm} {
    flex-direction: column;
    position: relative;
    left: 60px;
  }
`;

S.LineItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

interface IDisabled {
  disabled: boolean;
}

S.ItemDescription = styled.div<IDisabled>`
  color: ${({ disabled }) => (disabled ? '#191919' : 'white')};
  position: absolute;
  top: 40px;
  ${mediaQueries.sm} {
    top: 0;
    left: -150px;
  }
`;

S.LineDivision = styled.div`
  width: 96px;
  height: 2px;
  background-color: #191919;

  ${mediaQueries.sm} {
    width: 2px;
    height: 16px;
  }
`;

S.CheckCircleIcon = styled(CheckCircleIcon)<IDisabled>`
  color: ${({ disabled }) => (disabled ? '#191919' : 'var(--success)')};
`;

S.BookCkeckIcon = styled(BookCkeckIcon)<IDisabled>`
  fill: ${({ disabled }) => (disabled ? '#191919' : 'var(--success)')}; ;
`;

export default ReportPreloader;
