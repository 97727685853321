import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from 'config';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.backend.apiEndpoint,
  }),
  endpoints: (builder) => ({
    getGallery: builder.query({
      query: () => 'nft/gallery',
    }),
    getNftByUrl: builder.query({
      query: ({ url, chainId }) =>
        `nft/${chainId}?url=${encodeURIComponent(url)}`,
      keepUnusedDataFor: 5,
    }),
    getNftByAddressAndToken: builder.query({
      query: ({ address, token, chainId }) =>
        `nft/${chainId}/${address}/${token}`,
      keepUnusedDataFor: 5,
    }),
    getLiteReport: builder.query({
      query: ({ address, tokenId, chainId }) =>
        `nft/report/${chainId}/${address}/${tokenId}/?type=lite`,
      keepUnusedDataFor: 5,
    }),
    getFullReport: builder.query({
      query: ({ address, tokenId, chainId }) =>
        `nft/report/${chainId}/${address}/${tokenId}/?type=full`,
      keepUnusedDataFor: 5,
    }),
    getSmartContractCode: builder.query({
      query: ({ address, chainId }) => `nft/sourceCode/${chainId}/${address}`,
      keepUnusedDataFor: 5,
    }),
    getPdf: builder.query({
      query: ({ address, tokenId, chainId }) =>
        `nft/report/${chainId}/${address}/${tokenId}/?type=pdf`,
      keepUnusedDataFor: 5,
    }),
    getEnergyConsumption: builder.query({
      query: ({ address, tokenId, chainId }) =>
        `nft/report/energyConsumption/${chainId}/${address}/${tokenId}`,
      keepUnusedDataFor: 5,
    }),
    getContractType: builder.query({
      query: ({ contractType }) => `nft/contractType/${contractType}`,
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetGalleryQuery,
  useGetNftByUrlQuery,
  useGetNftByAddressAndTokenQuery,
  useGetLiteReportQuery,
  useGetFullReportQuery,
  useGetSmartContractCodeQuery,
  useGetPdfQuery,
  useGetEnergyConsumptionQuery,
  useGetContractTypeQuery,
} = api;

// HubSput email subscription
export const subscribeEmail = (
  email: string,
  callback: (response) => void,
  callbackError: (error) => void
) => {
  const portalId = config.emailSubscription.portalId;
  const formGuid = config.emailSubscription.formGuid;

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
  const data = {
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: email,
      },
      {
        objectTypeId: '0-1',
        name: 'product_source',
        value: 'nft_grade',
      },
    ],
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      callbackError(error);
    })
    .then((response) => {
      callback(response);
    });
};
