import { getEnvVar } from 'utils';

interface AppConfig {
  backend: {
    apiEndpoint: string;
  };
  emailSubscription: {
    portalId: string;
    formGuid: string;
  };
}

export const config: AppConfig = {
  backend: {
    apiEndpoint: getEnvVar('REACT_APP_API_ENDPOINT', true),
  },
  emailSubscription: {
    portalId: getEnvVar('REACT_APP_HUBSPOT_PORTAL_ID', true),
    formGuid: getEnvVar('REACT_APP_HUBSPOT_FORM_GUID', true),
  },
};
