import { useState, useEffect } from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReactComponent as EtherIcon } from 'assets/images/icons/ether.svg';
import { ReactComponent as HederaIcon } from 'assets/images/icons/hedera.svg';
import { ReactComponent as PolygonIcon } from 'assets/images/icons/polygon.svg';
import Grade from 'components/Grade';
import MuiAccordion from 'components/MuiAccordion';

import * as S from '../../styles';
import Co2Chart from './components/Co2Chart';

const Energy = ({ isExpanded, energyData }) => {
  const [selectValue, setSelectValue] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value);
  };

  const normalizeByName = (key) => {
    return (data, item) => {
      const atr = item.attributes.reduce((d, i) => {
        d[i[key]] = i;
        return d;
      }, {});
      data[item[key]] = { ...item, attributes: atr };
      return data;
    };
  };

  //Normalized sub categories
  const subCategories =
    energyData.energy_consumption_report.sub_categories.reduce(
      normalizeByName('name'),
      {}
    );
  const currentChain = subCategories['Current Chain'];
  const simulatedChain = subCategories['Simulated Chain'];
  const currentChainName =
    currentChain?.chain_id === 1
      ? 'ethereum'
      : currentChain?.chain_id === 2
      ? 'hedera'
      : 'polygon';

  const TotalKgCo2 = currentChain?.attributes['Total CO2 spent']?.value;
  const TotalCO2Simulated =
    simulatedChain?.attributes['Total CO2 spent']?.value;
  const TotalGas = currentChain?.attributes['Total Gas Used'].value;
  const avgGasCO2 = TotalKgCo2 / TotalGas;

  const filterChainCategory = (category: string, chain) => {
    const cat = category === 'gas' ? 'hbar' : 'gas'; //Inverse selected value for the filter function
    const filteredItems: any = [];

    Object.keys(chain.attributes).forEach((attrName) => {
      if (
        !attrName.toLowerCase().includes(cat) &&
        !attrName.includes('Total CO2 spent')
      ) {
        filteredItems.push(chain.attributes[attrName]);
      }
    });

    return filteredItems;
  };

  useEffect(() => {
    const initialSelectValue =
      currentChainName === 'ethereum' || currentChainName === 'polygon'
        ? 'gas'
        : 'hbar';

    setSelectValue(initialSelectValue);
  }, [currentChainName]);

  return (
    <div id="energy-consumption">
      <MuiAccordion
        id="energy-consumption"
        isExpanded={isExpanded}
        title={
          <div>
            <div style={{ display: 'flex' }}>
              <BoltIcon />
              <div>Energy Consumption</div>
            </div>
            <S.EnergyValueLeft>
              ~
              {energyData.energy_consumption_report.value?.toLocaleString() || 0}{' '}
              kWh
            </S.EnergyValueLeft>
          </div>
        }
        rightTitle={
          <S.EnergyValueRight>
            <span
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
              }}
            >
              ~
              {energyData.energy_consumption_report.value?.toLocaleString() || 0}{' '}
              kWh
            </span>
            <Grade size="md">{undefined}</Grade>
          </S.EnergyValueRight>
        }
      >
        <S.ECDetail>
          <S.ECBlock>
            <div>
              <S.EnergyMainTitle>
                <S.GasUnitsWrapper>
                  <small>Gas Units: </small>

                  <FormControl
                    sx={{ m: 1, minWidth: 120, marginLeft: 0 }}
                    size="small"
                  >
                    <Select
                      value={selectValue}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="hbar">Hbar</MenuItem>
                      <MenuItem value="gas">Gas</MenuItem>
                    </Select>
                  </FormControl>
                </S.GasUnitsWrapper>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <small style={{ textAlign: 'right' }}>Current Chain:</small>

                  {currentChainName === 'ethereum' && (
                    <>
                      <EtherIcon
                        style={{
                          fill: 'black',
                        }}
                      />
                      <span>Ethereum</span>
                    </>
                  )}

                  {currentChainName === 'hedera' && (
                    <>
                      <HederaIcon
                        style={{
                          fill: 'black',
                        }}
                      />
                      <span>Hedera</span>
                    </>
                  )}

                  {currentChainName === 'polygon' && (
                    <>
                      <PolygonIcon
                        style={{
                          fill: 'black',
                        }}
                      />
                      <span>Polygon</span>
                    </>
                  )}
                </div>
              </S.EnergyMainTitle>
            </div>

            <S.SubCategoryTitle style={{ marginTop: '30px' }}>
              NFT Transactions
            </S.SubCategoryTitle>

            {filterChainCategory(selectValue, currentChain).map((el, key) => (
              <S.EnergyConsumptionItem key={key}>
                <span>{el.name}</span>
                <span>
                  {el.value?.toLocaleString()}{' '}
                  {el.name !== 'Total Transactions' && selectValue}
                </span>
              </S.EnergyConsumptionItem>
            ))}
          </S.ECBlock>

          <S.ECBlock>
            <div>
              <S.EnergyMainTitle>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <small>Compared with:</small>

                  {(currentChainName === 'ethereum' ||
                    currentChainName === 'polygon') && (
                    <>
                      <HederaIcon
                        style={{
                          fill: 'black',
                        }}
                      />
                      <span>Hedera</span>
                    </>
                  )}

                  {currentChainName === 'hedera' && (
                    <>
                      <EtherIcon
                        style={{
                          fill: 'black',
                        }}
                      />
                      <span>Ethereum</span>
                    </>
                  )}
                </div>
              </S.EnergyMainTitle>
            </div>

            <S.SubCategoryTitle style={{ marginTop: '30px' }}>
              NFT Transactions
            </S.SubCategoryTitle>

            {filterChainCategory(selectValue, simulatedChain).map((el, key) => (
              <S.EnergyConsumptionItem key={key}>
                <span>{el.name}</span>
                <span>
                  {el.value?.toLocaleString()}{' '}
                  {el.name !== 'Total Transactions' && selectValue}
                </span>
              </S.EnergyConsumptionItem>
            ))}
          </S.ECBlock>
        </S.ECDetail>

        <S.ECBlock>
          <Co2Chart
            totalCO2={TotalKgCo2}
            totalSimulatedCO2={TotalCO2Simulated}
            currentChainName={currentChainName}
          />
          <S.ECBottomInfo>
            <p>
              <small>kgCo2/Gas avg:</small> {avgGasCO2}
            </p>
          </S.ECBottomInfo>
        </S.ECBlock>
      </MuiAccordion>
    </div>
  );
};

export default Energy;
