import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const MobileMenu = ({ tooggleMenu, tooggleSearchNft }) => {
  return (
    <S.Continer>
      <S.Menu>
        <S.MenuItem>
          <Link to="/" onClick={tooggleSearchNft}>
            Grade an NFT
          </Link>
        </S.MenuItem>
        <S.MenuItem onClick={tooggleMenu}>
          <Link to="/#gallery-landing">Examples</Link>
        </S.MenuItem>
        <S.MenuItem onClick={tooggleMenu}>
          <Link to="/">Authenticate (Coming Soon)</Link>
        </S.MenuItem>
        <S.MenuItem onClick={tooggleMenu}>
          <Link to="/">Connect Wallet (Coming Soon)</Link>
        </S.MenuItem>
      </S.Menu>
      <S.Menu>
        <S.MenuItem onClick={tooggleMenu}>
          <Link to="/faqs">FAQs</Link>
        </S.MenuItem>
        <S.MenuItem onClick={tooggleMenu}>
          <Link to="/artist-statement">Artist Statement</Link>
        </S.MenuItem>
        <S.MenuItem onClick={tooggleMenu}>
          <S.HtmlLink href="https://goinfinite.io/terms" target="_blank">
            Terms & Conditions
          </S.HtmlLink>
        </S.MenuItem>
      </S.Menu>
    </S.Continer>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Continer = styled.div`
  display: flex;
  margin-top: 88px;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 80px;
  overflow: hidden;
  margin-top: 110px;
`;

S.Menu = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  color: #444444;
  font-weight: 400;
`;

S.MenuItem = styled.li`
  list-style-type: none;
  &:hover {
    background-color: var(--secundary);
    color: white;
    cursor: pointer;
  }
  a {
    padding-left: 32px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
  }
`;

S.HtmlLink = styled.a`
  transition: 0.4s;
  text-decoration: none;
  color: black;
`;

export default MobileMenu;
