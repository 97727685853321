import MuiAccordion from '@mui/material/Accordion';
import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Container = styled.div`
  position: relative;
  min-height: 500px;
  background-color: var(--pale);

  strong {
    font-weight: 500;
  }
`;

export const Title = styled.h1`
  margin-bottom: 48px;
`;

export const Content = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 96px;
  background-color: white;

  ${mediaQueries.sm} {
    padding: 64px 24px 64px 24px;
  }
`;

export const Accordion = styled(MuiAccordion)`
  box-shadow: none !important;

  &:before {
    display: none;
  }

  .MuiPaper-root {
    border: 0;
  }

  .MuiAccordionSummary-root {
    height: 64px;
    background-color: var(--pale);
    border: 1px solid var(--lightGrey);
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px 0;
  }

  .MuiAccordionDetails-root {
    padding: 0 16px 24px;
  }
`;

export const AccordionContent = styled.div`
  padding-top: 20px;
  border-top: 1px solid var(--lightGrey);
  font-size: 1rem;
`;
