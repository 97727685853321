import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import * as S from './styles';

const ArtistStatement = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>FAQs</S.Title>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>What is a condition report?</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Condition reports are a tool artists, curators, conservators,
              insurance companies, appraisers, and museum professionals use to
              keep track of the changing physical condition of artworks and
              their attendant structures such as frames, pedestals, etc.
            </p>

            <p>
              When kept systematically, condition reports document any changes
              over time. In physical art objects, these changes may be caused
              by: natural aging of sensitive materials, accidental damage,
              vandalism, infestation, inappropriate display or storage
              conditions, improper handling or inappropriate packaging for
              travel, as well as any alteration to the work due to a restoration
              treatment.
            </p>

            <p>NFT Grade introduces condition reports for NFTs.</p>
          </AccordionDetails>
        </S.Accordion>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>Why do a condition report?</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Would you buy a used car without first taking it to a mechanic or
              seeking a report on its history? Would you buy a house without an
              inspection? Can you get a loan with the bank running a credit
              report? These are all examples of condition reports.
            </p>

            <p>
              Artists, collectors, and purveyors of NFTs deserve to understand
              where their NFTs came from, where they’ve been, and the condition
              of the smart contract and the associated media.
            </p>
          </AccordionDetails>
        </S.Accordion>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>Ok, so tell me how you grade NFTs</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              We grade NFTs based on the quality of its code and on the quality
              of the storage platform that houses the associated media. We DO
              NOT grade NFTs based on the quality of the artwork itself - the
              artist’s expression.
            </p>

            <p>
              We are currently grading 11 criteria across 2 different
              categories: Code and Media.
            </p>

            <p>
              We will be rapidly expanding the number of criteria we grade over
              the coming months, simplifying our grading rubric, and expanding
              the FAQ so you can better understand what each criteria means.
            </p>
          </AccordionDetails>
        </S.Accordion>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>What’s next for NFT Grade?</h5>
          </AccordionSummary>
          <AccordionDetails>
            We will be working hard over the next several months to expand
            support for additional blockchains, develop a better understanding
            of collection behavior, dive deeper into environmental impact and
            offsets, and integrate into marketplaces across the Hedera ecosystem
            and beyond.
          </AccordionDetails>
        </S.Accordion>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>What is a Hedera Hashgraph?</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Hashgraph is a distributed ledger technology that has been
              described as an alternative to blockchains. The hashgraph
              technology is currently patented, and the only authorized ledger
              is Hedera Hashgraph. The native cryptocurrency of the Hedera
              Hashgraph system is HBAR.
            </p>

            <p>
              Unlike blockchains, hashgraphs do not bundle data into blocks or
              use miners to validate transactions. Instead, hashgraphs use a
              &quot;gossip about gossip&quot; protocol where the individual
              nodes on the network &quot;gossip&quot; about transactions to
              create directed acyclic graphs that time-sequence transactions.
              Each &quot;gossip&quot; message contains one or more transactions
              plus a timestamp, a digital signature, and cryptographic hashes of
              two earlier events. This makes Hashgraph form an asynchronous
              Byzantine Fault-Tolerant (aBFT) consensus algorithm.
            </p>

            <p>
              What this means is that the Hedera network is faster, cheaper,
              greener, and safer than almost any other crypto network.
            </p>

            <p>
              The Hedera network has already processed over 2 billion
              transactions — more than Bitcoin, Ethereum, and many other crypto
              projects with much higher valuations.
            </p>

            <p>
              The network is owned and governed by 26 of the world’s leading
              organizations including Google, Boeing, LG, IBM, Deutsche Telecom,
              Avery Dennison and the London School of Economics.
            </p>
          </AccordionDetails>
        </S.Accordion>

        <S.Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h5>Contract Language</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Contract Language refers to the two NFT standards that exist
              currently - ERC-721 and ERC-1155 (ERC - Ethereum Request for
              Comments).
            </p>

            <p>
              ERC-721 are non-fungible tokens where each one of them is unique,
              which means that there is only one copy of each NFT. They have an
              ID associated and can be traded or transferred from one address to
              another.
            </p>

            <p>
              ERC-1155 are semi-fungible tokens where each NFT can have several
              copies of itself. So we can refer to it as NFT editions, where
              each edition can have more than one copy. Each edition has an
              associated ID, but it can contain several copies and owners
              inside. So you can think of this as an ERC-721 collection, where
              each NFT can have several copies.
            </p>

            <p>
              (To clarify, an edition would represent each of the different NFTs
              inside a collection)
            </p>

            <p>
              A critical difference between these two contracts is how they
              address an NFT with its owner. When using ERC-721, each NFT has a
              unique Owner, so it is easy to get who this is. However, when
              using ERC-1155, each NFT can have multiple Owners (as there can be
              several copies of these NFTs). So the way they store who the NFT
              owners are is slightly different from ERC-721. Instead of
              addressing who is the owner of an NFT, it addresses how many NFTs
              an account holds for a specific edition. So, they are
              semi-fungible because instead of just owning one NFT, they can
              have a balance of 5 NFTs for a particular edition.
            </p>
          </AccordionDetails>
        </S.Accordion>
      </S.Content>
    </S.Container>
  );
};

export default ArtistStatement;
