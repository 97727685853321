import BoltIcon from '@mui/icons-material/Bolt';

import Grade from 'components/Grade';
import MuiAccordion from 'components/MuiAccordion';
import Show from 'components/Show';

import Code from './components/Code';
import Energy from './components/Energy';
import Media from './components/Media';
import Provenance from './components/Provenance';
import * as S from './styles';

const CriteriaReport = ({
  data,
  dataEnergy,
  reportType,
  errorContractCode,
  toggleSourceCodeModal,
}) => {
  const criteriaCategories = data.condition_report.criteria_categories;
  const { data: energyData, isLoading: energyLoading } = dataEnergy;

  return (
    <>
      <S.SectionTitle>Criteria Report</S.SectionTitle>

      <S.SectionContent>
        {criteriaCategories.map((categoryData, key) => {
          if (categoryData.name === 'Code') {
            return (
              <Code
                key={key}
                categoryData={categoryData}
                isExpanded={reportType === 'code'}
                errorContractCode={errorContractCode}
                toggleSourceCodeModal={toggleSourceCodeModal}
              />
            );
          }

          if (categoryData.name === 'Media') {
            return (
              <Media
                key={key}
                categoryData={categoryData}
                isExpanded={reportType === 'media'}
              />
            );
          }
        })}

        <Show when={energyLoading || energyData}>
          {energyLoading ? (
            <MuiAccordion
              id="energy-consumption"
              isExpanded={true}
              title={
                <div>
                  <div style={{ display: 'flex' }}>
                    <BoltIcon />
                    <div>Energy Consumption</div>
                  </div>
                </div>
              }
              rightTitle={
                <S.EnergyValueRight>
                  <Grade size="md">{undefined}</Grade>
                </S.EnergyValueRight>
              }
            >
              ... Loading energy data
            </MuiAccordion>
          ) : (
            <Energy
              energyData={energyData}
              isExpanded={reportType === 'energy-consumption'}
            />
          )}
        </Show>

        <Provenance isExpanded={reportType === 'provenance'} />
      </S.SectionContent>
    </>
  );
};

export default CriteriaReport;
