import { useEffect } from 'react';

import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ReactComponent as IconDotClose } from 'assets/images/icons/button-dot-close.svg';
import { ReactComponent as IconDot } from 'assets/images/icons/button-dot.svg';
import { ReactComponent as Logo } from 'assets/images/logos/logo-grade.svg';
import { toggleMobileMenu, closeMobileMenu } from 'store/globalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mediaQueries } from 'styles/media';

import MobileMenu from './components/MobileMenu';
import NavBarMenu from './components/NavBarMenu';

const NavBar = ({ tooggleSearchNft }) => {
  const dispatch = useAppDispatch();
  const mobileMenuVisibility = useAppSelector(
    (state) => state.global.ui.mobileMenuVisibility
  );
  const matchesMobile = useMediaQuery('(max-width:960px)');
  const tooggleMenu = () => {
    dispatch(toggleMobileMenu());
  };

  useEffect(() => {
    if (!matchesMobile) {
      dispatch(closeMobileMenu());
    }
  }, [matchesMobile]);

  return (
    <S.Container>
      <S.StickyContainer>
        <Link to="/">
          <Logo />
        </Link>
        <NavBarMenu tooggleSearchNft={tooggleSearchNft} />
        <S.ToggleIconContainer>
          {mobileMenuVisibility ? (
            <IconDotClose onClick={tooggleMenu} />
          ) : (
            <IconDot onClick={tooggleMenu} />
          )}
        </S.ToggleIconContainer>

        <S.DrawerMenu
          anchor="right"
          open={mobileMenuVisibility}
          hideBackdrop={true}
        >
          <MobileMenu
            tooggleMenu={tooggleMenu}
            tooggleSearchNft={tooggleSearchNft}
          />
        </S.DrawerMenu>
      </S.StickyContainer>
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.header`
  height: 88px;
  position: relative;
  z-index: 1250;
`;

S.StickyContainer = styled.div`
  position: fixed;
  height: 88px;
  top: 0;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding: var(--desktopViewPadding);
  background-color: #c3c3c3;
 
  ${mediaQueries.md} {
    padding: var(--mobileViewPadding);
  }
`;

S.ToggleIconContainer = styled.div`
  cursor: pointer;
  display: none;

  ${mediaQueries.md} {
    display: block;
  }
`;

S.DrawerMenu = styled(Drawer)`
  top: 88px !important;
  .MuiPaper-root {
    width: 100vw;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #000000;
    box-shadow: none;
  }
`;

export default NavBar;
