import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const SlyderContainer = styled.div`
  display: none;
  padding-bottom: 15px;
  background-color: white;

  ${mediaQueries.md} {
    display: block;
  }
`;

export const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25vw);
  grid-auto-rows: 25vw;

  ${mediaQueries.md} {
    display: none;
  }
`;
