import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import PillButton from 'components/Buttons/PillButton';

import * as S from './styles';

type MyProps = {
  children: JSX.Element;
};
type MyState = { error: string | null; errorInfo: string | null };

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render(): JSX.Element {
    if (this.state.errorInfo) {
      // Error path
      return (
        <S.Container>
          <S.Title>
            <ErrorOutlineIcon /> Something went wrong!
          </S.Title>
          <PillButton
            label="Try reload"
            onClick={() => window.location.reload()}
          />

          <PillButton
            label="Back home"
            onClick={() => (window.location.href = '/')}
          />
        </S.Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
