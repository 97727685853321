import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import { mediaQueries } from 'styles/media';

interface IParams {
  close: () => void;
  errorMessage?: string;
  resetStatus?: () => void | undefined;
}

const SubscribeError = ({ close, errorMessage, resetStatus }: IParams) => {
  return (
    <S.Continer>
      <h2>Oops! Something went wrong.</h2>
      <S.ErrorCode>{errorMessage || 'Error'}</S.ErrorCode>
      <p>
        We could not subscribe you, please refresh your browser and try again or
        reach us through our social medias so we can assist you.
      </p>

      <S.ButtonsWrapper>
        <PillButton
          type="submit"
          label="Go Back"
          variant="outlined-black"
          icon="replay"
          onClick={close}
        />
        <PillButton
          type="submit"
          label="Try Again"
          variant="primary"
          icon="arrowFordward"
          onClick={resetStatus || close}
        />
      </S.ButtonsWrapper>
    </S.Continer>
  );
};

const S: any = {};

S.Continer = styled.div`
  height: 100%;
  position: relative;
`;

S.ErrorCode = styled.div`
  color: var(--error);
  font-weight: 600;
`;

S.ButtonsWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 24px;

  ${mediaQueries.md} {
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

export default SubscribeError;
