import styled from 'styled-components/macro';

import PillButton from 'components/Buttons/PillButton';
import { mediaQueries } from 'styles/media';

export const Sidebar = styled.aside`
  padding: 48px 64px 64px 64px;
  text-align: center;
  display: grid;
  grid-template-rows: 70px min-content auto min-content;

  ${mediaQueries.lg} {
    grid-template-rows: 50px min-content auto min-content;
  }

  ${mediaQueries.md} {
    padding: 24px;
  }

  ${mediaQueries.tabletLandscape} {
    padding: 48px 24px 48px 24px;
  }
`;

export const Detail = styled.div`
  height: fit-content;
  align-self: center;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-all;

  ${mediaQueries.md} {
    margin-top: 40px;
  }
`;

export const MetaData = styled.div`
  margin-bottom: 27px;
  color: var(--grey);
  small {
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--darkGrey);
    }
  }
`;

export const BottomButtons = styled.div`
  ${mediaQueries.md} {
    background-color: white;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    padding: 32px 24px 32px 24px;
    border-top: 1px solid var(--lightGrey);
    z-index: 200;
  }
`;

export const Divider = styled.hr`
  background-color: var(--lightGrey);
  height: 1px;
  border: 0;
  margin: 40px 0 40px 0;
  ${mediaQueries.lg} {
    margin: 20px 0 20px 0;
  }
  ${mediaQueries.md} {
    margin: 40px 0 40px 0;
  }
`;

export const StyledPillBtn = styled(PillButton)`
  box-shadow: 0 0 0 0 var(--secundary);
  animation: ${(props) =>
    props.scrollYPos > 20 ? 'none' : 'ripples 1.5s infinite'};
  @keyframes ripples {
    0% {
      box-shadow: 0 0 0 0 var(--secundary);
    }

    70% {
      box-shadow: 0 0 0 25px #6566f300;
    }

    100% {
      box-shadow: 0 0 0 0 #6566f300;
    }
  }
  animation-iteration-count: 3;
`;

export const TopNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: start;
  justify-content: end;
`;

export const TopNavButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--darkGrey);
  svg {
    fill: black;
  }
`;

export const SearchButton = styled.div`
  margin-top: 40px;
  font-weight: 600;
  cursor: pointer;
`;

export const CreatedBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 500;
`;
