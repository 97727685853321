import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const TextContainer = styled.div`
  box-sizing: border-box;
  align-items: center;
  padding: 8px 16px 8px 16px;
  min-height: 64px;
  width: 92%;
  position: absolute;
  z-index: 10;
  bottom: 16px;
  border: none;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #ffffff;
  cursor: pointer;

  display: grid;
  grid-template-columns: auto 1fr auto;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: left;
  word-break: break-all;
  padding-right: 10px;
  padding-left: 10px;
`;

export const Name = styled.small`
  color: var(--darkGrey);
`;

export const Description = styled.span`
  word-break: break-word;
  font-size: 20px;
`;

export const ArrowIcon = styled(ArrowCircleLeftRoundedIcon)`
  transform: rotate(180deg);
  color: var(--secundary);
`;

export const Continer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    transition: all 0.5s ease;
  }

  &:hover {
    img {
      transform: scale(1.1);
      width: 100%;
    }

    ${TextContainer} {
      background-color: black;
      color: white;
      width: 96%;
      bottom: 10px;
    }

    ${Name} {
      color: white;
    }
  }
`;

export const Spinner = styled.div`
  position: absolute;
  color: var(--lightGrey);
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
`;
