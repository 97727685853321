import MuiAccordion from '@mui/material/Accordion';
import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Accordion = styled(MuiAccordion)`
  box-shadow: none !important;
  border: 1px solid var(--lightGrey);
  padding: 8px;

  &:before {
    display: none;
  }

  &.MuiAccordion-rounded {
    border-radius: 8px !important;

    ${mediaQueries.md} {
      border-radius: 0 !important;
      border-left: none;
      border-right: none;
    }
  }
`;

export const Content = styled.div`
  padding-top: 20px;
  border-top: 1px solid var(--lightGrey);
  font-size: 1rem;
`;

export const Title = styled.h5`
  display: flex;
  align-items: center;
  gap: 10px;
  ${mediaQueries.xs} {
    gap: 8px;
  }
`;

export const RightTitle = styled.span`
  position: absolute;
  right: 50px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--darkGrey);
`;
