import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ImageGalleryItem from 'views/Landing/components/ImageGallery/components/ImageGalleryItem';

import * as S from './styles';

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 4000,
  speed: 850,
};

const ImageGallery = ({ data }) => {
  const galleryItems = data?.map((nftData) => (
    <ImageGalleryItem
      key={nftData._id}
      nftData={nftData}
      to={`/nft/preview/${nftData._id}`}
    />
  ));

  return (
    <>
      <S.GalleryGrid>{galleryItems}</S.GalleryGrid>

      <S.SlyderContainer>
        <Slider {...settings}>{galleryItems}</Slider>
      </S.SlyderContainer>
    </>
  );
};

export default ImageGallery;
