import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Continer = styled.div``;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 20px;

  ${mediaQueries.sm} {
    h3 {
      font-size: 20px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const TopBarButton = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border-radius: 28px;
  color: black;

  svg {
    fill: black !important;
  }
`;

export const CodeContainer = styled.div`
  max-height: 400px;
  //padding: 24px 24px 24px 24px;
  border-radius: 8px;
  overflow: scroll;
  background-color: var(--dark);
  color: var(--grey);
  line-height: 24px;
  font-size: 1rem;
  ${mediaQueries.sm} {
    max-height: 100%;
    width: 100vw;
    margin-left: -24px;
  }

  pre {
    background: var(--dark) !important;
    color: var(--grey) !important;
  }
`;
