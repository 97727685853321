import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Continer = styled.div`
  box-sizing: border-box;

  ${mediaQueries.md} {
    background-color: white;
  }
`;

export const Video = styled.video`
  max-height: 64vh;

  ${mediaQueries.md} {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
  }
`;

export const SliderArrow = styled.img`
  cursor: pointer;
  transform: rotate(90deg);

  ${mediaQueries.md} {
    transform: unset;
  }
`;
