import { useNavigate } from 'react-router-dom';

import PillButton from 'components/Buttons/PillButton';
import Modal from 'components/Modals/Modal';

import * as S from './styles';

const DownloadPdfModal = ({ isOpen, errorData, close }) => {
  const navigate = useNavigate();

  const refreshPage = () => {
    location.reload();
  };

  const closeAndRedirect = () => {
    close();
    navigate('/');
  };

  return (
    <Modal isOpen={isOpen} close={closeAndRedirect}>
      <S.Container>
        <div>
          <h2>Error! Retrieving condition report failed.</h2>
          <p>
            {errorData.message ||
              'We could not generate the condition report, please try again or go back to the homepage to try another example.'}
          </p>
        </div>

        <S.ButtonsContainer>
          <PillButton
            label="Back to Homepage"
            variant="outlined-black"
            onClick={closeAndRedirect}
          />

          {errorData.statusCode !== 404 && (
            <PillButton label="Try Again" icon="replay" onClick={refreshPage} />
          )}
        </S.ButtonsContainer>
      </S.Container>
    </Modal>
  );
};

export default DownloadPdfModal;
