import styled, { css } from 'styled-components/macro';

export const Continer = styled.div`
  //border: 1px solid red;
  width: 100%;
  font-weight: 400;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  font-weight: 600;
`;

interface Item {
  isFirst?: boolean;
}

export const Item = styled.div<Item>`
  margin: 8px 0 8px 0;
  padding: 8px 16px 8px 16px;

  ${({ isFirst }) => {
    if (isFirst) {
      return css`
        background-color: var(--pale);
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        color: black !important;
      `;
    }
  }}
`;

export const ItemTitle = styled.div<Item>`
  margin-bottom: 8px;
  color: var(--grey);
  font-weight: 400;

  ${({ isFirst }) => {
    if (isFirst) {
      return css`
        color: black !important;
      `;
    }
  }}
`;

export const Scale = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 24px;
`;

export const ScaleItem = styled.div`
  height: 8px;
  position: relative;
  border: solid black;
  border-right: 0;
  border-bottom: 0;
  border-top-width: 2px;
  border-left-width: 2px;
`;

export const ScaleValue = styled.span`
  position: absolute;
  top: 20px;
  left: -5px;
  color: var(--grey);
`;
