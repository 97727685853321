import styled from 'styled-components/macro';

const TermsConditions = () => {
  return (
    <S.Container>
      <h1>Terms & Conditions</h1>
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.div`
  min-height: 500px;
  text-align: center;

  h1 {
    margin-top: 100px;
  }
`;

export default TermsConditions;
