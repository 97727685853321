import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

//import { ReactComponent as LinkIcon } from 'assets/images/icons/link.svg';
import Modal from 'components/Modals/Modal';

import * as S from './styles';

const CodeModal = ({ isOpen, close, contractCode, bgColor, info }) => {
  const code = contractCode?.nft?.sourceCode;

  const copyCode = () => {
    navigator.clipboard.writeText(code).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
  const downloadCode = () => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(code)
    );
    link.setAttribute('download', 'nft-source-code.txt');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      bgColor={bgColor}
      backdropBgColor="#ffffffd4"
    >
      <>
        <S.TopBar>
          <h3>NFT Source Code</h3>
          <S.ButtonsContainer>
            <S.TopBarButton onClick={copyCode}>
              <ContentCopyIcon />
            </S.TopBarButton>
            <S.TopBarButton onClick={downloadCode}>
              <DownloadIcon />
            </S.TopBarButton>
            {/* <S.TopBarButton>
              <LinkIcon />
            </S.TopBarButton> */}
          </S.ButtonsContainer>
        </S.TopBar>

        <S.CodeContainer>
          <SyntaxHighlighter
            style={vs2015}
            wrapLines
            language="javascript"
            showLineNumbers
            lineNumberStyle={{ color: 'var(--grey)', minWidth: '2.25em' }}
          >
            {code}
          </SyntaxHighlighter>
        </S.CodeContainer>
      </>
    </Modal>
  );
};

export default CodeModal;
