/**
 * Pull variables from environment.
 *
 * @param name Name of the environment variable
 * @param required If required, throw an error when missing.
 */
export const getEnvVar = (name: string, required = false): string => {
  const value = process.env[name];
  if (!value) {
    const message = `environment variable ${name} not found.`;
    if (required) {
      console.error(`getEnvVar: Required ${message}`);
      throw new Error(`Required ${message}`);
    } else {
      console.log(`getEnvVar: Optional ${message}`);
    }
  }
  return value || '';
};

export const scrollToHtmlEl = (elementId: string, margin: number) => {
  const el = document.querySelector(elementId) as HTMLInputElement;
  if (el) {
    const topOfElement = el.offsetTop - margin;
    window.scroll({ top: topOfElement, behavior: 'smooth' });
  }
};

export const formatDate = (date: string) => {
  const dateObj = new Date(date);

  if (!isNaN(dateObj.getTime())) {
    return dateObj?.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      // timeZone: 'UTC',
      // timeZoneName: 'short',
    });
  }

  return '';
};

export const formatText = (text: string): string => {
  if (text && text.length > 15) {
    return `${text.slice(0, 6)}...${text.slice(-5)}`;
  }
  return text;
};

export const capitalize = (word: string) => {
  if (word) {
    if (typeof word === 'string') {
      const loweredCase = word.toLowerCase();
      return word[0].toUpperCase() + loweredCase.slice(1);
    } else if (typeof word === 'number') return word;
  }
};
