import { useState } from 'react';

//import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
//import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { ReactComponent as DiscordIcon } from 'assets/images/logos/discord-logo.svg';
import { ReactComponent as Isotype } from 'assets/images/logos/grade-iso-black.svg';
//import { ReactComponent as InfiniteIcon } from 'assets/images/logos/infinite-icon.svg';
import PillButton from 'components/Buttons/PillButton';
import FooterMenu from 'components/Footer/components/FooterMenu';
import TextField from 'components/Form/TextField';
import Modal from 'components/Modals/Modal';
import SubscribeError from 'components/Subscribe/SubscribeError';
import SubscribeSuccess from 'components/Subscribe/SubscribeSuccess';
import { subscribeEmail } from 'services';
import { mediaQueries } from 'styles/media';

function pixelsToVw(width: number) {
  return (width * 100) / 1728;
}

function validateEmail(val) {
  let error;
  const regEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!val) {
    error = 'Required';
  } else if (!regEx.test(val)) {
    error = 'Enter a valid email';
  }
  return error;
}

const Footer = ({ tooggleSearchNft }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [successSubscription, setSuccessSubscription] = useState(false);
  const [errorSubscription, setErrorSubscription] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = (values, { resetForm }) => {
    setIsFetching(true);
    const { email } = values;

    const callback = (response) => {
      resetForm();
      setIsFetching(false);
      if (response.status === 'error') {
        if (response.errors[0].errorType === 'INVALID_EMAIL') {
          return toast.error('This email is not accepted');
        }
        setErrorSubscription('Error');
      } else {
        setEmail(values.email);
        setSuccessSubscription(true);
      }
    };

    const callbackError = (error) => {
      resetForm();
      setIsFetching(false);
      setErrorSubscription('Error');
      console.log('error: ', error);
    };

    subscribeEmail(email, callback, callbackError);
  };

  const closeModal = () => {
    setSuccessSubscription(false);
    setErrorSubscription('');
  };

  return (
    <S.Container>
      <Modal isOpen={successSubscription} close={closeModal}>
        <SubscribeSuccess close={closeModal} email={email} />
      </Modal>

      <Modal isOpen={errorSubscription} close={closeModal}>
        <SubscribeError close={closeModal} errorMessage={errorSubscription} />
      </Modal>

      <S.Top>
        <Link to="/">
          <Isotype />
        </Link>

        <FooterMenu tooggleSearchNft={tooggleSearchNft} />

        <S.FormContainer>
          <Formik initialValues={{ email: '' }} onSubmit={onSubmit}>
            {({ errors, touched, values, isValid }) => (
              <S.Form>
                <S.InputLabel htmlFor="email">Subscribe</S.InputLabel>
                <S.InputWrapper>
                  <Field
                    as={TextField}
                    type="name"
                    name="email"
                    value={values.email}
                    placeholder="Enter your email address"
                    validate={validateEmail}
                    errorMessage={errors.email}
                    touched={touched.email}
                    width={'100%'}
                  />
                  <S.PillButton
                    type="submit"
                    label="Register"
                    variant="primary"
                    width="100%"
                    disabled={
                      Object.values(values).some(
                        (formElementVal) => formElementVal === ''
                      ) || !isValid
                    }
                    loading={isFetching}
                    icon="emailOutlined"
                  />
                </S.InputWrapper>
              </S.Form>
            )}
          </Formik>

          <S.SocialLogos data-testid="social-logos">
            <a
              href="https://twitter.com/thenftgrade"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>

            <a
              href="https://discord.gg/NrKPgvUN6N"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon />
            </a>
          </S.SocialLogos>
        </S.FormContainer>
      </S.Top>
      <S.Bottom>
        <S.Copy>Infinite Assets, Inc.© 2022 All rights reserved.</S.Copy>
        <a
          href="http://infiniteworld.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.PoweredText>Powered by InfiniteWorld</S.PoweredText>
        </a>
      </S.Bottom>
      <ToastContainer />
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: var(--desktopViewPadding);
  padding-top: 48px;
  border-top: 1px solid var(--lightGrey);
  text-align: center;

  ${mediaQueries.md} {
    padding: var(--mobileViewPadding);
    padding-top: 48px;
  }
`;

S.Top = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 42px;

  ${mediaQueries.md} {
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
  }
`;

S.SocialLogos = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  width: max-content;
  margin-top: 50px;

  svg {
    font-size: 32px;
    cursor: pointer;
  }

  ${mediaQueries.md} {
    margin-top: 0;
  }
`;

S.FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${pixelsToVw(517)}vw;
  max-width: 517px;

  ${mediaQueries.md} {
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
    max-width: 100%;
  }
`;

S.Form = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueries.md} {
    margin-bottom: 50px;
    width: 100%;
  }
`;

S.FormTitle = styled.span`
  text-align: left;
  margin-bottom: 16px;
  ${mediaQueries.md} {
    text-align: center;
  }
`;

S.Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
  padding: 20px 0 20px 0;
  color: var(--darkGrey);

  ${mediaQueries.md} {
    flex-direction: column;
    align-items: center;
    padding: 40px 0 40px 0;
  }
`;

S.Copy = styled.small`
  ${mediaQueries.md} {
    margin-bottom: 25px;
  }
`;

S.PoweredText = styled.small`
  display: flex;
  align-items: center;
`;

S.InputWrapper = styled.div`
  display: flex;
  //align-items: baseline;

  ${mediaQueries.md} {
    flex-direction: column;
  }
`;

S.InputLabel = styled.label`
  text-align: left;
  margin-bottom: 16px;
  ${mediaQueries.md} {
    text-align: center;
  }
`;

S.PillButton = styled(PillButton)`
  width: max-content;
  position: relative;
  left: -8px;
  height: 59px;
  border-radius: 0 28px 28px 0;

  ${mediaQueries.md} {
    margin-top: 24px;
    border-radius: 28px;
    width: 100%;
    left: auto;
  }
`;

export default Footer;
