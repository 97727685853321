import styled, { css } from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

import { PillButton as IPillButton } from './index';

export const Label = styled.span<IPillButton>`
  grid-column: ${(props) => (props.iconLeftPos ? 2 : 1)};
`;

const primary = css`
  border: 1px solid black;
  background-color: #000000;
  color: #ffffff;
  fill: white;
  &:hover {
    background-color: white;
    color: #000000;
    fill: black;
  }
`;

const secundary = css`
  background-color: var(--secundary);
  color: #ffffff;
  &:hover {
    background-color: var(--accentDark);
    color: #ffffff;
  }
`;

const outlined = css`
  border: 1px solid var(--secundary);
  background-color: #ffffff;
  color: var(--secundary);
  &:hover {
    border: 1px solid var(--accentDark);
    color: var(--accentDark);
  }
`;

const outlinedBlack = css`
  border: 1px solid black;
  background-color: #ffffff;
  color: black;
  svg {
    fill: black;
  }
  &:hover {
    background-color: black;
    color: #ffffff;
    fill: black;
    svg {
      fill: #ffffff;
    }
  }
`;

const outlinedTransparent = css`
  border: 1px solid black;
  background-color: transparent;
  color: black;
  svg {
    fill: black;
  }
  &:hover {
    background-color: black;
    color: white;
    fill: black;
    svg {
      fill: white;
    }
  }
`;

const white = css`
  background-color: white;
  color: #000000;
  fill: black;
  &:hover {
    color: white;
    background-color: black;
    fill: white;
  }
`;

const disabled = css`
  background-color: var(--grey);
  color: white;
`;

const disabledSecundary = css`
  background-color: var(--dark);
  color: var(--darkGrey);
`;

const outlinedDisabled = css`
  border: 1px solid var(--grey20);
  background-color: white;
  color: var(--grey20);
`;

export const StyledButton = styled.button<IPillButton>`
  display: flex;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.small ? '40px' : '56px')};
  padding: 16px 24px 16px 24px;
  border-radius: 28px;
  border: 0;
  justify-content: ${(props) => (props.icon ? 'space-between' : 'center')};
  gap: 20px;
  align-items: center;
  transition: 0.3s;
  background: #6566f3;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;

  ${mediaQueries.sm} {
    width: ${(props) => (props.width ? props.width : '100%')};
  }

  ${(props) => {
    switch (props.variant) {
      case 'secundary':
        if (props.disabled) return disabledSecundary;
        return secundary;
      case 'outlined':
        if (props.disabled) return outlinedDisabled;
        return outlined;
      case 'outlined-black':
        if (props.disabled) return outlinedDisabled;
        return outlinedBlack;
      case 'outlined-transparent':
        if (props.disabled) return outlinedTransparent;
        return outlinedTransparent;
      case 'white':
        if (props.disabled) return outlinedDisabled;
        return white;
      default:
        if (props.disabled) return disabled;
        return primary;
    }
  }};
`;
