import { ReactComponent as CodeIcon } from 'assets/images/icons/code.svg';
import Grade from 'components/Grade';
import MuiAccordion from 'components/MuiAccordion';

import ConditionItem from '../../../ConditionItem';
import * as S from '../../styles';

const Media = ({ categoryData, isExpanded }) => {
  return (
    <div id="media">
      <MuiAccordion
        id="media"
        isExpanded={isExpanded}
        title={
          <>
            <CodeIcon />
            <span>{categoryData.name}</span>
          </>
        }
        rightTitle={
          <S.CodeButtonWrapper>
            <Grade size="md">{categoryData.condition_letter}</Grade>
          </S.CodeButtonWrapper>
        }
      >
        {categoryData?.attributes && (
          <S.ConditionItems>
            {categoryData?.attributes.map((attribute, key) => (
              <ConditionItem attribute={attribute} key={key} />
            ))}
          </S.ConditionItems>
        )}

        {categoryData?.sub_categories &&
          categoryData?.sub_categories.map((category, k) => (
            <span key={k}>
              <S.SubCategoryTitle style={{ marginTop: '30px' }}>
                {category.name}
              </S.SubCategoryTitle>

              <S.ConditionItems>
                {category.attributes.map((attribute, key) => (
                  <ConditionItem attribute={attribute} key={key} />
                ))}
              </S.ConditionItems>
            </span>
          ))}
      </MuiAccordion>
    </div>
  );
};

export default Media;
