import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const ButtonsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  ${mediaQueries.md} {
    position: fixed;
    bottom: 0;
    z-index: 210;
    background-color: white;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    border-top: 1px solid var(--lightGrey);
  }
`;
