import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

const FooterMenu = ({ tooggleSearchNft }) => {
  return (
    <S.Container data-testid="footer-menu">
      <S.NavItems>
        <S.LeftItems>
          <li>
            <S.Link to="/" onClick={tooggleSearchNft}>
              Grade an NFT
            </S.Link>
          </li>
          <li>
            <S.Link to="/#gallery-landing">Examples</S.Link>
          </li>
          <li>
            <S.Link to="/" style={{ color: 'var(--grey)' }}>
              Authenticate (Coming Soon)
            </S.Link>
          </li>
          <li>
            <S.Link to="/" style={{ color: 'var(--grey)' }}>
              Connect Wallet (Coming Soon)
            </S.Link>
          </li>
        </S.LeftItems>
        <S.RightItems>
          <li>
            <S.Link to="/faqs">FAQs</S.Link>
          </li>
          <li>
            <S.Link to="/artist-statement">Artist Statement</S.Link>
          </li>
          <li>
            <S.HtmlLink href="https://goinfinite.io/terms" target="_blank">
              Terms & Conditions
            </S.HtmlLink>
          </li>
          <li>
            <S.HtmlLink href="https://goinfinite.io/privacy" target="_blank">
              Privacy Policy
            </S.HtmlLink>
          </li>
          <li>
            <S.Link to="/cookie-policy">Cookie Policy</S.Link>
          </li>
          <li>
            <a href="mailto:support@nftgrade.com">
              Do Not Sell My Personal Information
            </a>
          </li>
        </S.RightItems>
      </S.NavItems>
    </S.Container>
  );
};

/* ==========================================================================
Styled-Components
========================================================================== */
const S: { [key: string]: any } = {};

S.Container = styled.nav``;

S.NavItems = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 35.5vw;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 16px;
  }
  ${mediaQueries.md} {
    flex-direction: column;
    width: auto;
  }
`;

S.LeftItems = styled.div`
  text-align: left;
  ${mediaQueries.md} {
    text-align: center;
  }
`;

S.RightItems = styled.div`
  text-align: left;
  ${mediaQueries.md} {
    text-align: center;
  }
`;

S.Link = styled(Link)`
  transition: 0.4s;
  text-decoration: none;
  color: black;
`;

S.HtmlLink = styled.a`
  transition: 0.4s;
  text-decoration: none;
  color: black;
`;

export default FooterMenu;
