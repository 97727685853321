import * as S from './styles';

const ArtistStatement = () => {
  return (
    <S.Container>
      <S.Content>
        <h1>NFT Grade Cookie Policy</h1>
        <p>
          <strong>
            To make this site work properly, we sometimes place small data files
            called cookies on your device. A cookie is a small text file that a
            website saves on your computer or mobile device when you visit the
            site. It enables the website to remember your actions and
            preferences over a period of time, so you do not have to keep
            re-entering them whenever you come back to the site or browse from
            one page to another.
          </strong>
        </p>

        <hr style={{ width: '148px' }} />

        <div>
          <h5>
            Cookies on{' '}
            <a target="_blank" rel="noreferrer" href="https://www.nftgrade.io/">
              <u>www.nftgrade.io</u>
            </a>
          </h5>
          <p>
            The following table sets out the different categories of cookies
            that our site uses, and why we use them. The lists of third-party
            cookie providers are intended merely as illustrative and should not
            be viewed as a comprehensive list.
          </p>
        </div>

        <S.Grid>
          <S.GridTrack>
            <S.GridCell>
              <strong>Type of Cookie</strong>
            </S.GridCell>

            <S.GridCell>
              <strong>Purpose</strong>
            </S.GridCell>

            <S.GridCell>
              <strong>Who Serves</strong>
              <small>(for example)</small>
            </S.GridCell>
          </S.GridTrack>

          <S.GridTrack>
            <S.GridCell>
              <S.CellTitle>Type of Cookie</S.CellTitle>
              <div>Strictly Necessary</div>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>Purpose</S.CellTitle>
              <p>
                These cookies are essential to enable your use of the site or
                services, and are used to help make our website work
                efficiently, provide security features on our website, and
                provide services you have asked for, like shopping carts or
                billing.
              </p>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>
                Who Serves <small>(for example)</small>
              </S.CellTitle>

              <ul>
                <li>Google</li>
                <li>Dropbox</li>
                <li>Stripe</li>
              </ul>
            </S.GridCell>
          </S.GridTrack>

          <S.GridTrack>
            <S.GridCell>
              <S.CellTitle>Type of Cookie</S.CellTitle>
              <p>Performance/Analytics</p>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>Purpose</S.CellTitle>
              <p>
                These cookies are used to analyze the way our website works and
                how we can improve it. For example, we may use cookies to
                understand how you are using certain site features.
              </p>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>
                Who Serves <small>(for example)</small>
              </S.CellTitle>
              <ul>
                <li>Google</li>
              </ul>
            </S.GridCell>
          </S.GridTrack>

          <S.GridTrack>
            <S.GridCell>
              <S.CellTitle>Type of Cookie</S.CellTitle>
              <div>Functionality</div>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>Purpose</S.CellTitle>
              <p>
                These cookies help to enhance your experience by remembering
                choices you have made concerning the features of the website
                (e.g., username, language, currency, region you are in).
              </p>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>
                Who Serves <small>(for example)</small>
              </S.CellTitle>
              <ul>
                <li>DropBox</li>
              </ul>
            </S.GridCell>
          </S.GridTrack>

          <S.GridTrack>
            <S.GridCell>
              <S.CellTitle>Type of Cookie</S.CellTitle>
              <div>Advertising/Targeting</div>
            </S.GridCell>

            <S.GridCell>
              <div>
                <S.CellTitle>Purpose</S.CellTitle>
              </div>
              <p>
                These cookies and pixels are used to deliver advertisements more
                relevant to you and your interests. They remember that you have
                visited a website and this information is shared with other
                organizations such as advertisers.
              </p>
            </S.GridCell>

            <S.GridCell>
              <S.CellTitle>
                Who Serves <small>(for example)</small>
              </S.CellTitle>
              <ul>
                <li>Google</li>
                <li>Dropbox</li>
                <li>Stripe</li>
              </ul>
            </S.GridCell>
          </S.GridTrack>
        </S.Grid>

        <S.Row>
          <h5>Cookies and You</h5>
          <p>
            To sign up for an account or make a purchase online, you must have
            cookies enabled on your internet browser. If you choose not to
            enable cookies, you will still be able to browse our website, but it
            will restrict some of the functionality of our website or what you
            can do.
          </p>
        </S.Row>

        <S.Row>
          <h5>Controlling your cookies</h5>
          <p>
            You can also manage many companies&apos; cookies used for online
            advertising via the consumer choice tools created under
            self-regulation programs in many countries, such as the US-based
            aboutads.info choices page or the EU-based Your Online Choices.
          </p>
        </S.Row>
        <hr style={{ marginBottom: '24px' }} />
        <S.BottomDate>Last updated on February 2022</S.BottomDate>
      </S.Content>
    </S.Container>
  );
};

export default ArtistStatement;
