import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

interface IContainer {
  stepAnimationVisible?: boolean;
}

export const Container = styled.main<IContainer>`
  display: grid;
  grid-template-rows: ${(props) =>
    props.stepAnimationVisible
      ? 'min-content 1fr'
      : 'min-content min-content 1fr'};
  min-height: calc(100vh - 88px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 64px;
  border-bottom: 1px solid var(--lightGrey);
  background-color: white;
  color: black;

  ${mediaQueries.md} {
    padding: 16px 24px;
    flex-direction: column;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 64px;
  border-bottom: 1px solid var(--lightGrey);

  ${mediaQueries.md} {
    display: none;
  }
`;

export const GradeLevel = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  ${mediaQueries.md} {
    padding: 24px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const HeaderTitle = styled.h2`
  cursor: pointer;
  ${mediaQueries.md} {
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const NoDataAvailable = styled.div`
  display: inline-block;
  margin: auto;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 24px;
`;