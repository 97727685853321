import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const Continer = styled.main`
  display: grid;
  grid-template-columns: 66% 34%;
  min-height: calc(100vh - 88px);

  ${mediaQueries.md} {
    grid-template-columns: 100%;
  }
`;

export const MediaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--pale);
  ${mediaQueries.sm} {
    padding: 0;
  }
`;

export const Video = styled.video`
  max-height: 64vh;
`;
