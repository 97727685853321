import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NftState {
  lastPreviewed: any;
}

const initialState: NftState = {
  lastPreviewed: null,
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setLastPreviewed: (state, action: PayloadAction<any>) => {
      state.lastPreviewed = action.payload;
    },
    removeLastPreviewed: (state) => {
      state.lastPreviewed = null;
    },
  },
});

const { actions, reducer } = nftSlice;
export const { setLastPreviewed, removeLastPreviewed } = actions;
export default reducer;
