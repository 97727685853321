import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

import PillAddressButton from 'components/Buttons/PillAddressButton';
import { toggleDrawer } from 'store/globalSlice';
import { useAppDispatch } from 'store/hooks';
import { setLastPreviewed } from 'store/nftSlice';

import * as S from './styles';

const PreviewDetails = ({ data, generateReport, searchButtonVisible }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tooggleSearchNft = () => {
    dispatch(toggleDrawer());
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return dateObj?.toLocaleString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  useEffect(() => {
    dispatch(setLastPreviewed(data));
  }, [data]);

  const ownerData = data?.owner[0];
  const ensOwnerName = ownerData?.ens_owner_name;
  const ownerName = ownerData?.owner_name;
  const purchaseDate = ownerData?.purchase_date;
  const nftName = data?.name || (data?.token_id ? `#${data?.token_id}` : null);
  const chainName =
    data.chain_id === 1
      ? 'ethereum'
      : data.chain_id === 2
      ? 'hedera'
      : 'polygon';

  const tokenType = data.token_type;

  return (
    <S.Sidebar>
      <S.TopNav>
        <S.TopNavButton role="button" onClick={() => navigate('/')}>
          Close &nbsp; <CloseIcon />
        </S.TopNavButton>
      </S.TopNav>
      <h2>Generate Condition Report for this NFT</h2>

      <S.Detail>
        <S.Summary>
          {purchaseDate && (
            <S.CreatedBy>
              {ensOwnerName}

              <PillAddressButton label={ownerName} icon={chainName} />
            </S.CreatedBy>
          )}

          <h3>{nftName}</h3>

          {purchaseDate && (
            <div style={{ color: 'var(--darkGrey)' }}>
              {formatDate(purchaseDate)}
            </div>
          )}
        </S.Summary>

        <S.Divider />

        <S.MetaData>
          <small>
            {chainName === 'ethereum' || chainName === 'polygon'
              ? 'Contract Address'
              : 'Token ID'}
          </small>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '4px',
            }}
          >
            <PillAddressButton
              label={data?.contract_address}
              icon="copy"
              iconPositionRight
            />
          </div>
        </S.MetaData>

        <S.MetaData>
          <small>
            {chainName === 'ethereum' || chainName === 'polygon'
              ? tokenType === 'ERC-1155'
                ? 'Batch ID '
                : 'Token ID '
              : 'Serial #'}
          </small>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '4px',
            }}
          >
            <PillAddressButton
              label={data?.token_id}
              icon="copy"
              iconPositionRight
            />
          </div>
        </S.MetaData>
      </S.Detail>

      <S.BottomButtons>
        <S.StyledPillBtn
          label="Generate Condition Report"
          variant="secundary"
          width="100%"
          onClick={generateReport}
        />

        {searchButtonVisible && (
          <S.SearchButton role="button" onClick={tooggleSearchNft}>
            Search for another NFT
          </S.SearchButton>
        )}
      </S.BottomButtons>
    </S.Sidebar>
  );
};

export default PreviewDetails;
