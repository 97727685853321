import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const GradeContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ConditionItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto max-content 1fr;
  justify-items: end;
  align-items: center;
  padding: 8px 16px;
  background: var(--pale);
  border: 1px solid var(--lightGrey);
  box-sizing: border-box;
  border-radius: 8px;
  gap: 16px;
  span {
    word-break: break-word;
  }
  ${mediaQueries.md} {
    font-size: 14px;
    padding-right: 42px;
  }
`;
