import useMediaQuery from '@mui/material/useMediaQuery';

import Grade from 'components/Grade';
import PreviewImage from 'components/PreviewImage';

import SummaryDetail from './components/SummaryDetail';
import * as S from './styles';

const Component = ({ data, dataEnergy }) => {
  const matchesMobile = useMediaQuery('(max-width:960px)');
  return (
    <S.Content>
      <S.LeftContent>
        {matchesMobile && (
          <S.GradeLevel>
            <h3>Grade</h3>
            <Grade>{data?.condition_report?.condition_letter}</Grade>
          </S.GradeLevel>
        )}
        <SummaryDetail data={data} dataEnergy={dataEnergy} />
      </S.LeftContent>
      <S.RightContent>
        {data.nft?.mediaType === 'video' ? (
          <S.Video
            key={data.nft._id}
            src={data.nft?.image_url}
            autoPlay
            muted
            controls={false}
            loop
          >
            <p>
              Your browser doesn&apos;t support HTML5 video. Here is a{' '}
              <a href={data.nft?.image_url} target="_blank" rel="noreferrer">
                link to the video
              </a>{' '}
              instead.
            </p>
          </S.Video>
        ) : (
          <PreviewImage src={data.nft?.image_url} virtualHeight={70} />
        )}
      </S.RightContent>
    </S.Content>
  );
};

export default Component;
