import styled from 'styled-components/macro';

import { mediaQueries } from 'styles/media';

export const SectionContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 128px 24px 128px;
  ${mediaQueries.lg} {
    margin: 24px;
  }
  ${mediaQueries.sm} {
    margin: 24px 0 24px 0;
  }
`;

export const SectionTitle = styled.h3`
  padding: 10px 64px 10px 64px;
  background-color: white;
  border-top: 1px solid var(--lightGrey);
  border-bottom: 1px solid var(--lightGrey);
`;

export const SubCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  font-weight: 600;
`;

export const ConditionItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  box-sizing: border-box;
  ${mediaQueries.md} {
    grid-template-columns: 1fr;
  }
`;

export const ECDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 24px;
  margin-bottom: 24px;

  ${mediaQueries.md} {
    grid-template-columns: 1fr;
    grid-gap: 24px 24px;
  }
`;

export const ECBlock = styled.div`
  border: 1px solid var(--grey);
  border-radius: 8px;
  padding: 24px 16px 0 16px;
`;

export const EnergyConsumptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: var(--pale);
  border: 1px solid var(--lightGrey);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
  span {
    word-break: break-word;
  }
`;

export const EnergyMainTitle = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const EnergyValueLeft = styled.div`
  display: none;
  font-weight: 600;
  padding-left: 14px;

  ${mediaQueries.md} {
    display: block;
  }
`;

export const EnergyValueRight = styled.div`
  display: flex;
  gap: 10px;

  ${mediaQueries.md} {
    display: none;
  }
`;

export const GasUnitsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mediaQueries.md} {
    align-items: flex-start;
    flex-direction: column;
    gap: inherit;
  }
`;

export const ECBottomInfo = styled.div`
  width: 100%;
  margin-top: 46px;
  text-align: right;
`;

export const HightLighted = styled.span`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const CodeButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaQueries.md} {
    display: none;
  }
`;

export const DisabledCodeBtn = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px 8px 24px;
  background: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 20px;
  color: var(--grey);
`;
